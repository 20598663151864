import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!

  return (_openBlock(), _createBlock(_component_DxCheckBox, {
    value: _ctx.modelValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
    visible: _ctx.visible,
    isValid: _ctx.isValid,
    readOnly: _ctx.readOnly,
    disabled: _ctx.disabled,
    hint: _ctx.hint,
    onValueChanged: _ctx.valueChanged,
    onOnContentReady: _ctx.contentReady
  }, null, 8, ["value", "visible", "isValid", "readOnly", "disabled", "hint", "onValueChanged", "onOnContentReady"]))
}