
import { Options, Vue } from "vue-class-component";
import api from "@/services/api";
import DxTextBox from "devextreme-vue/text-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxButton from "devextreme-vue/button";
import DxValidator, {
  DxRequiredRule,
  DxPatternRule,
  DxCustomRule,
} from "devextreme-vue/validator";
import User from "@/models/administration/user";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCircleNotch);

@Options({
  components: {
    DxTextBox,
    DxCheckBox,
    DxButton,
    DxValidator,
    DxRequiredRule,
    DxPatternRule,
    DxCustomRule,
    FontAwesomeIcon,
  },
})
export default class FormLogin extends Vue {
  user = new User("", "", "", false);
  loading = false;
  error = "";

  async onLogin(e: any) {
    let result = e.validationGroup.validate();
    this.error = "";

    if (result.isValid) {
      this.loading = true;
      (this as any).$store.dispatch("auth/login", this.user).then(
        () => {
          const newPath = (this as any).$store.getters[
            "auth/getRememberedPath"
          ];

          (this as any).$store.dispatch("settings/clearSettings");

          this.$router.push(newPath);
        },
        (error: any) => {
          this.loading = false;

          if (error.response?.status === 404) {
            this.error = "Неверные логин или пароль";
            return;
          } else {
            this.error =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        }
      );
    }
  }
}
