import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40c7c574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-inline-block ImportProgressDialog-progressBarWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxProgressBar = _resolveComponent("DxProgressBar")!
  const _component_DxScrollView = _resolveComponent("DxScrollView")!
  const _component_DxToolbarItem = _resolveComponent("DxToolbarItem")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_openBlock(), _createBlock(_component_DxPopup, {
    visible: _ctx.popupVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.popupVisible) = $event)),
    "drag-enabled": true,
    "close-on-outside-click": false,
    "show-title": true,
    showCloseButton: false,
    width: 680,
    height: 230,
    shading: true,
    resizeEnabled: true,
    title: _ctx.title,
    onHidden: _ctx.onHidden,
    onShown: _ctx.onShown
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxScrollView, {
        width: "100%",
        height: "100%",
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.message.Message), 1),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DxProgressBar, {
              class: "ImportProgressDialog-progressBar",
              id: "progress-bar-status",
              min: 0,
              max: 100,
              "status-format": _ctx.progressStatusFormat,
              value: _ctx.progressValue,
              width: "100%"
            }, null, 8, ["status-format", "value"])
          ])
        ]),
        _: 1
      }),
      (_ctx.closeButtonVisible)
        ? (_openBlock(), _createBlock(_component_DxToolbarItem, {
            key: 0,
            options: _ctx.closeButtonOptions,
            widget: "dxButton",
            location: "after",
            toolbar: "bottom"
          }, null, 8, ["options"]))
        : (_openBlock(), _createBlock(_component_DxToolbarItem, {
            key: 1,
            options: _ctx.stopButtonOptions,
            widget: "dxButton",
            location: "after",
            toolbar: "bottom",
            disabled: _ctx.stopping
          }, null, 8, ["options", "disabled"]))
    ]),
    _: 1
  }, 8, ["visible", "title", "onHidden", "onShown"]))
}