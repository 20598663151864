import api from "./api";
import store from "@/store";
import { settingsId } from "@/const/idConstant";

class SettingsService {
  setSettings() {
    return api
      .get(`Settings/GetEditModel`, {
        params: {
          id: settingsId,
        },
      })
      .then((response) => {
        const temp = {
          settings: {
            useGroupByCargo: (response as any).data.useGroupByCargo,
            separationWeight: (response as any).data.separationWeight,
            showSchemeFromVehicleBack: (response as any).data
              .showSchemeFromVehicleBackInPlan,
            showSchemeCuts: (response as any).data.showSchemeCutsInPlan,
            showSchemeLevels: (response as any).data.showSchemeLevelsInPlan,
          },
        };

        store.dispatch("settings/setSettings", temp);
      });
  }
}

export default new SettingsService();
