
import { defineComponent } from "vue";
import {
  DxPopup as DxEditPopup,
  DxToolbarItem as DxEditToolbarItem,
  DxPosition as DxEditPosition,
} from "devextreme-vue/popup";

import { DxScrollView } from "devextreme-vue/scroll-view";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";

export default defineComponent({
  components: {
    DxEditPopup,
    DxEditToolbarItem,
    DxEditPosition,
    DxScrollView,
  },
  props: {
    saveModelCallback: { required: true },
    maxWidth: { default: "900px" },
    minWidth: { default: null },
    height: { default: null },
    maxHeight: { default: "calc(100vh - 60px)" },
  },
  computed: {
    isModelEmpty() {
      return Object.keys(this.createEditModel).length === 0;
    },
  },
  watch: {
    isModelEmpty: function (value: any) {
      //TODO: потом убрать (сейчас нужно для перерисовки содержимого при изменении isModelEmpty)
      if (!value) this.renderKey++;
    },
  },
  methods: {
    onShown() {
      this.$emit("onShown", this.createEditModel);
    },
    //действия при скрытии диалогового окна
    onHidden() {
      this.close();
    },
    open(model: any, editType: any) {
      if (editType === "edit") this.title = "Редактировать";
      else this.title = "Добавить";
      this.editType = editType;
      this.createEditModel = model;
      this.visible = true;
    },
    close() {
      this.$emit("close");
      this.createEditModel = {};
      this.visible = false;
    },
    save(e: any) {
      this.$emit("saveData", e);
      let isValid = true;
      if (e.validationGroup) {
        const res = e.validationGroup.validate();
        isValid = res.isValid;
      }
      if (isValid) {
        (this as any).saveModelCallback(
          this.createEditModel,
          this,
          this.editType
        );
      }
    },
  },
  data() {
    return {
      guidHelper: new GuidHelper(),
      validationId: null,
      renderKey: 0,
      title: "",
      editType: "",
      createEditModel: {},
      visible: false,
      okButtonOptions: {
        validationGroup: "",
        text: "ОК",
        type: "default",
        onClick: (e: any) => {
          this.save(e);
        },
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: () => {
          (this as any).createEditModel = {};
          this.close();
        },
      },
    };
  },
  created() {
    this.validationId = this.guidHelper.getUniqueId(4);
    this.okButtonOptions.validationGroup =
      "baseValidationGroup_" + this.validationId;
  },
});
