
import { defineComponent } from "vue";
import ImportExportProgressDialog from "@/components/workflows/importExport/ImportExportProgressDialog.vue";
import ExportSelectDialog from "@/components/workflows/importExport/exportWorkflow/ExportSelectDialog.vue";
import { TableExportConfig } from "@/components/controls/catalogueGrid/tableExportConfig";
import { ImportExportType } from "./../ImportExportType";

export default defineComponent({
  components: {
    ImportExportProgressDialog,
    ExportSelectDialog,
  },
  props: {
    controller: { required: true },
    exportConfig: { required: true, type: TableExportConfig },
  },
  methods: {
    start(idParent: any) {
      this.idParent = idParent;
      this.exportDialog().show(true);
    },
    onPassCallbacks(callbacks: any) {
      this.exportProgressDialog().show(true, callbacks, this.idParent);
    },
    exportDialog() {
      return this.$refs.exportSelectDialog as any;
    },

    exportProgressDialog() {
      return this.$refs.exportProgressDialog as any;
    },
  },
  data() {
    return {
      idParent: null,
      editingConfig: {},
      mode: ImportExportType.Export,
    };
  },
});
