
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxSelectBox } from "devextreme-vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import { defineComponent } from "vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import api from "@/services/api";
import { DataFillValueType } from "./DataFillValueType";
import DateControl from "@/components/controls/base/DateControl.vue";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import { ColumnTemplateType } from "./columns/enums";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxCheckBox,
    DxSelectBox,
    TextControl,
    CheckBoxControl,
    IntegerControl,
    DecimalControl,
    DxScrollView,
    DateControl,
    CatalogueSelectControl,
  },
  props: {
    controller: { required: true },
    parentModel: { required: true },
    columns: { required: true },
    dataGrid: { required: true },
  },
  computed: {
    isTextControl() {
      return this.selectedColumn.editTemplateName == ColumnTemplateType.Text;
    },
    isIntegerControl() {
      return this.selectedColumn.editTemplateName == ColumnTemplateType.Integer;
    },
    isDecimalControl() {
      return this.selectedColumn.editTemplateName == ColumnTemplateType.Decimal;
    },
    isCheckBoxControl() {
      return this.selectedColumn.editTemplateName == ColumnTemplateType.Boolean;
    },
    isDateControl() {
      return (
        this.selectedColumn.editTemplateName == ColumnTemplateType.DateTime
      );
    },
    isCatalogueControl() {
      return (
        this.selectedColumn.editTemplateName == ColumnTemplateType.Catalogue
      );
    },
    cataloguePath() {
      if (this.isCatalogueControl)
        return this.selectedColumn.catalogueComponentName;
      else return null;
    },
  },
  methods: {
    getColumnValue() {
      if (this.isTextControl) return this.columnValue;
      else if (this.isDateControl && this.columnValue) {
        var date = new Date(this.columnValue);
        return date.toISOString();
      } else return this.columnValue.toString();
    },
    getValueType() {
      if (this.isTextControl) return DataFillValueType.Text;
      if (this.isIntegerControl) return DataFillValueType.Integer;
      if (this.isDecimalControl) return DataFillValueType.Decimal;
      if (this.isCheckBoxControl) return DataFillValueType.Boolean;
      if (this.isDateControl) return DataFillValueType.Date;
      if (this.isCatalogueControl) return DataFillValueType.Catalogue;
    },
    show(visible: boolean, rowsData: any) {
      this.rowsData = rowsData;
      this.popupVisible = visible;
    },
    onContentReady(e: any): void {
      this.dxPopup = e.component;
    },
    onColumnValueChanged(): void {
      this.resetColumnValues();
      return;
    },
    onApply(e: any): void {
      if (this.isCatalogueControl) {
        let result = e.validationGroup.validate();
        if (!result.isValid) return;
      }

      let field = this.isCatalogueControl
        ? this.selectedColumn.modelIdField
        : this.selectedColumn.dataField;
      if (!field) {
        return;
      }
      const ids = [];
      this.rowsData.forEach((element: any) => {
        let isEmpty = this.isEmpty(element[field]);
        if ((isEmpty && this.changeEmpty) || (!isEmpty && this.changeNonEmpty))
          ids.push(element.id);
      });
      api
        .post(
          `${this.controller}/FillData`,
          {
            ids: ids,
            fieldName: field,
            value: this.getColumnValue(),
            valueType: this.getValueType(),
          },
          {
            params: {
              IdParent: this.parentModel.id,
            },
          }
        )
        .then(() => {
          this.$emit("onDataFillApply");
          this.dataGrid.refresh();
        });
      this.closePopup();
      return;
    },
    closePopup() {
      this.show(false, this.rowsData);
      this.columnValue = null;
      this.changeNonEmpty = true;
      this.changeEmpty = true;
      this.selectedColumn = {
        dataField: null,
        caption: null,
        editTemplateName: ColumnTemplateType.Text,
      };
    },
    resetColumnValues() {
      if (
        this.isIntegerControl ||
        this.isDecimalControl ||
        this.isCatalogueControl
      )
        this.columnValue = 0;
      else if (this.isCheckBoxControl) this.columnValue = false;
      else this.columnValue = null;
      this.catalogueDescription = null;
    },
    isEmpty(value: any) {
      return !value || value === "";
    },
  },
  data() {
    return {
      rowsData: {},
      columnValue: null,
      catalogueDescription: null,
      changeNonEmpty: true,
      changeEmpty: true,
      dxPopup: {},
      okButtonOptions: {
        text: "Применить",
        onClick: this.onApply,
        validationGroup: "dataFillValidationGroup",
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: this.closePopup,
      },
      popupVisible: false,
      selectedColumn: {
        dataField: null,
        caption: null,
        editTemplateName: ColumnTemplateType.Text,
      },
    };
  },
});
