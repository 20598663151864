
import { defineComponent } from "vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import UploadSelectControl from "@/components/controls/base/UploadSelectControl.vue";
import axios from "axios";
import TokenService from "@/services/tokenService";
import { DxCheckBox } from "devextreme-vue/check-box";
import api from "@/services/api";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    UploadSelectControl,
    DxCheckBox,
  },
  emits: ["passCallbacks"],
  watch: {
    files: function (e: any) {
      if (e.length) this.uploadSelectControl().validate();
    },
  },
  props: {
    controller: { required: true },
    importConfig: { required: true },
    planId: { required: true },
  },
  methods: {
    show(visible: boolean) {
      this.popupVisible = visible;
      if (!visible) {
        this.files = [];
        this.uploadSelectControl().clear();
      }
    },
    async onImport() {
      let isValid = this.uploadSelectControl().validate();

      if (!isValid) return;

      const ext = `.${this.files[0].name.split(".").pop()}`;
      isValid &= this.extensions.includes(ext);

      //if (isValid) { //TODO: Расскоментировать когда будет решена проблема
      const file = this.files[0];

      this.$emit("passCallbacks", {
        file: file,
        startCallbackAsync: async (
          file,
          jobId,
          idParent,
          controller,
          importExportConfig
        ) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("jobId", jobId);
          formData.append("planId", idParent);
          formData.append("isDataReplaced", this.isDataReplaced);

          await axios
            .create({ baseURL: "/" })
            .post(`${controller}/${importExportConfig.startMethod}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + TokenService.getLocalAccessToken(),
              },
            });
        },
      });
      this.show(false);
      //}
    },
    onCancel() {
      this.show(false);
    },

    uploadSelectControl() {
      return this.$refs.uploadSelectControl as any;
    },
    async getClearBeforeImportValue() {
      const { data } = await api.get(
        `${this.controller}/GetClearBeforeImportValue`,
        {
          params: {
            planId: this.planId,
          },
        }
      );
      this.isDataReplaced = data;
    },
  },
  created() {
    this.getClearBeforeImportValue();
  },
  data() {
    return {
      isDataReplaced: true,
      files: [],
      extensions: [".xls", ".xlsx", ".XLS", ".XLSX"], //TODO: вынести расширения в const
      importButtonOptions: {
        text: "Импорт",
        onClick: this.onImport,
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: this.onCancel,
      },
      popupVisible: false,
    };
  },
});
