
import { Options, Vue } from "vue-class-component";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";

import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

@Options({
  components: { AuthLayout, MainLayout },
})
export default class App extends Vue {
  get layout() {
    if ((this.$route as any).meta.layout) {
      return (this.$route as any).meta.layout + "-layout";
    } else return "PageNotFound";
  }

  created() {
    loadMessages(ruMessages);
    locale("ru");
  }
  /*
   get currentUser(){
     return (this as any).$store.state.auth.user;
   }

   get showAdminBoard() {
     return true;
   }

   get showModeratorBoard(){
     return true;
   }

   logOut() {
      (this as any).$store.dispatch('auth/logout');
      this.$router.push('/login');
    }*/
}
