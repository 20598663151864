
import { defineComponent } from "vue";
import { TableImportConfig } from "@/components/controls/catalogueGrid/tableImportConfig";
import ImportExportProgressDialog from "@/components/workflows/importExport/ImportExportProgressDialog.vue";
import ImportSelectDialog from "@/components/workflows/importExport/importWorkflow/ImportSelectDialog.vue";
import { ImportExportType } from "./../ImportExportType";

export default defineComponent({
  components: {
    ImportExportProgressDialog,
    ImportSelectDialog,
  },
  props: {
    controller: { required: true },
    importConfig: { required: true, type: TableImportConfig },
    planId: { required: true },
  },
  emits: ["importExportClose"],
  methods: {
    start(idParent: any) {
      this.idParent = idParent;
      this.importDialog().show(true);
    },
    onPassCallbacks(callbacks: any) {
      this.importProgressDialog().show(true, callbacks, this.idParent);
    },
    onImportExportClose() {
      this.$emit("importExportClose");
    },
    importDialog() {
      return this.$refs.importSelectDialog as any;
    },

    importProgressDialog() {
      return this.$refs.importProgressDialog as any;
    },
  },
  data() {
    return {
      idParent: null,
      editingConfig: {},
      mode: ImportExportType.Import,
    };
  },
});
