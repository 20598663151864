import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxEditPosition = _resolveComponent("DxEditPosition")!
  const _component_DxScrollView = _resolveComponent("DxScrollView")!
  const _component_DxEditPopup = _resolveComponent("DxEditPopup")!

  return (_openBlock(), _createBlock(_component_DxEditPopup, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    "drag-enabled": true,
    "max-width": _ctx.maxWidth,
    "min-width": _ctx.minWidth,
    height: _ctx.height,
    "show-title": true,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxEditPosition, {
        my: "center",
        at: "center",
        of: "window"
      }),
      _createVNode(_component_DxScrollView, {
        width: "100%",
        height: "100%"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "infoForm")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["visible", "max-width", "min-width", "height", "title"]))
}