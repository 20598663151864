/* eslint-disable */
export class ColumnConfig {
    //тип данных
    format?: string
    //тип данных
    type?: string
    //поле данных
    dataField!: string;

    dataType?: string;

    //название поля для группировки
    displayField?: string;
  
    //наименование
    caption!: string;
  
    //правила валидации
    validationRules? = [];
  
    //возможность редактирования
    readOnly?: boolean = false;
  
    //видимость колонки в таблице
    visible?: boolean = true;
  
    //видимость поля на форме редактирования
    editVisible?: boolean = true;
  
    //наименование шаблона для редактирования
    editTemplateName?: string;
  
    //значение по-умолчанию
    defaultValue?: any;
  
    //размер-колонки на форме редактирование
    editFormSpan?: number = 1;
  
    //показатель активности поля
    disabled?: boolean = false;
    disabledAction?: Function;
  
    repaintControls?: Array<string>;
  
    //фиксированная ширина колонки
    width?: number = undefined;
  
    //минимальная ширина колонки
    minWidth?: number = undefined;

    //максимальная ширина колонки
    cssClass?: string = undefined;
  
    //допуск пустых значений
    allowEmpty?: boolean;

    //отображаемое поле в колонке
    descriptionColumnName?: string;

    // callback для отображения данных
    customDisplayCallback?: any;

    allowFiltering?: boolean = true;

    allowSorting?: boolean = true;

    // порядок сортировки
    sortOrder?: string;

    // выравнивание
    alignment?: string;

    constructor(config: IColumnConfig) {
      Object.assign(this, config);
    }
  }
  
export interface IColumnConfig {
    format?: string;
    type?: string
    dataField: string;
    dataType?: string;
    displayField?: string;
    caption: string;
    validationRules?: Object[];
    readOnly?: boolean;
    editTemplateName?: string;
    defaultValue?: any;
    visible?: any;
    editFormSpan?: number;
    editVisible?: boolean;
    disabled?: boolean;
    width?: number;
    minWidth?: number;
    cssClass?: string;
    disabledAction?: Function;
    repaintControls?: Array<string>;
    allowEmpty?: boolean;
    descriptionColumnName?: string;
    customDisplayCallback?: any;
    allowFiltering?: boolean;
    allowSorting?: boolean;
    sortOrder?: string;
    alignment?: string;
  }
  