import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxEditPosition = _resolveComponent("DxEditPosition")!
  const _component_DxScrollView = _resolveComponent("DxScrollView")!
  const _component_DxEditToolbarItem = _resolveComponent("DxEditToolbarItem")!
  const _component_DxEditPopup = _resolveComponent("DxEditPopup")!

  return (_openBlock(), _createBlock(_component_DxEditPopup, {
    key: _ctx.renderKey,
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    "drag-enabled": true,
    "max-width": _ctx.maxWidth,
    "min-width": _ctx.minWidth,
    height: _ctx.height,
    "max-height": _ctx.maxHeight,
    "show-title": true,
    title: _ctx.title,
    onShown: _ctx.onShown,
    onHidden: _ctx.onHidden
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxEditPosition, {
        my: "center",
        at: "center",
        of: "window"
      }),
      _createVNode(_component_DxScrollView, {
        width: "100%",
        height: "100%"
      }, {
        default: _withCtx(() => [
          (!_ctx.isModelEmpty)
            ? _renderSlot(_ctx.$slots, "editForm", {
                key: 0,
                model: _ctx.createEditModel,
                validationGroup: _ctx.okButtonOptions.validationGroup
              })
            : _createCommentVNode("", true)
        ]),
        _: 3
      }),
      _createVNode(_component_DxEditToolbarItem, {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: _ctx.okButtonOptions
      }, null, 8, ["options"]),
      _createVNode(_component_DxEditToolbarItem, {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: _ctx.cancelButtonOptions
      }, null, 8, ["options"])
    ]),
    _: 3
  }, 8, ["visible", "max-width", "min-width", "height", "max-height", "title", "onShown", "onHidden"]))
}