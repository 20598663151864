
import { defineComponent } from "vue";
import MainMenu from "@/views/mainMenu/MainMenu.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import pollingMixin from "@/mixins/PollingMixin";

export default defineComponent({
  components: {
    MainMenu,
    DxScrollView,
  },
  mixins: [pollingMixin],
  props: {},
  methods: {
    onPollCallback(data: any) {
      this.serverTime = data;
    },
  },
  data() {
    return {
      serverTime: "",
    };
  },
  async created() {
    this.startPolling(
      `/ServerUtil/GetFormattedServerTime`,
      this.onPollCallback,
      60000
    );
  },
});
