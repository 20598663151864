
import {
  DxPopup as DxEditPopup,
  DxToolbarItem,
  DxPosition,
} from "devextreme-vue/popup";
import { DxTextBox, DxButton } from "devextreme-vue/text-box";
import { selectTitle, placeholderValue } from "@/const/patternStrings";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { defineComponent } from "vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";

export default defineComponent({
  components: {
    DxEditPopup,
    DxToolbarItem,
    DxPosition,
    DxTextBox,
    DxButton,
    DxValidator,
    DxRequiredRule,
  },
  mixins: [mountComponentMixin],
  emits: ["update:id", "update:desc", "changed"],
  props: {
    //фильтры
    filters: { required: false, default: [] },
    //панель выбора страницы
    showPageSizeSelector: { required: false, default: true },
    //поле в модели: идентификатор
    id: {},
    //поле в модели: отображаемое
    desc: {},
    //показатель взаимодействия
    disabled: { type: Boolean, required: false, default: false },
    //поле в выбираемой сущности: отображаемое
    displayDescField: { type: String, required: false, default: "name" },
    //поле в выбираемой сущности: ключевое
    keyField: { type: String, required: false, default: "id" },
    //catalogueComponentName
    catalogueComponentName: { type: String, required: false, default: null },
    //допустимость выбора пустого значения
    hasEmptyValue: { type: Boolean, required: false, default: false },
    // стиль
    stylingMode: { type: String, required: false, default: "underlined" },
    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },
    //модель родительского элемента
    parentModel: { required: false },
  },
  computed: {
    okButtonDisabled() {
      return this.selectedValues.length == 0;
    },
    isEmptyValue() {
      return !(this as any).id;
    },
  },
  methods: {
    //действия при показе диалгового окна
    async onShown() {
      //монтируем журнал в диалоговое окно
      if (this.parentModel) {
        (this as any).mountComponent(
          `components/${(this as any).catalogueComponentName}.vue`,
          this.catalogueComponentDomId,
          {
            selectMode: true,
            showPageSizeSelector: this.showPageSizeSelector,
            getSelectedData: this.getValueFromComponent,
            changeSelectedData: this.selectValueOnComponent,
            gridHeight: `${this.dialogHeight - this.dialogToGridHeightDelta}px`,
            parentModel: this.parentModel,
          }
        );
      } else {
        (this as any).mountComponent(
          `components/${(this as any).catalogueComponentName}.vue`,
          this.catalogueComponentDomId,
          {
            filters: await this.filters,
            selectMode: true,
            showPageSizeSelector: this.showPageSizeSelector,
            getSelectedData: this.getValueFromComponent,
            changeSelectedData: this.selectValueOnComponent,
            gridHeight: `${this.dialogHeight - this.dialogToGridHeightDelta}px`,
          }
        );
      }
    },

    //действия при скрытии диалогового окна
    onHidden() {
      //перерисовываем
      this.renderKey += 1;
      this.selectedValues = [];
    },

    //действия при монтировании диалогового окна и его содержимого
    onPopUpReady(e: any) {
      this.popUp = e.component;
    },

    //функция-обертка для вызова getValueFromComponet по нажатию на ОК
    //т.к. в самой функции обработчике ОК this работает только на функции, и
    //this.selectedValues не передать
    passValueToGet() {
      this.getValueFromComponent(this.selectedValues);
    },

    //закрыть диалоговое окно
    closePopUp() {
      this.popupVisible = false;
    },

    //открыть диалоговое окно
    showPopUp() {
      this.popupVisible = true;
    },

    //обработчик выбора значений в компоненте
    selectValueOnComponent(values: any) {
      this.selectedValues = values;
    },

    //обработчик получения значений
    getValueFromComponent(values: any) {
      this.closePopUp();
      if (values.length > 0) {
        const id = values[0][this.keyField];
        const desc = values[0][this.displayDescField];

        this.$emit("update:id", id);
        this.$emit("update:desc", desc);

        this.$emit("changed", { id, desc }); //для шаблонов в гридах
      }
      this.selectedValues = [];
    },

    clearValue() {
      this.$emit("update:id", undefined);
      this.$emit("update:desc", "");
      this.$emit("changed", { id: undefined, desc: "" }); //для шаблонов в гридах

      this.selectedValues = [];
    },
  },
  beforeUnmount() {
    (this as any).unmountComponent(this.catalogueComponentDomId);
  },
  data() {
    return {
      catalogueComponentDomId: "catalogueid",
      popUp: {},
      modelValue: {},
      dialogHeight: 600,
      dialogToGridHeightDelta: 170,
      designConfig: {
        popUpWidth: "80%",
        popUpHeight: `600px`,
      },
      componentInstance: null,
      renderKey: 0,
      selectedValues: [],
      popupVisible: false,
      selectTitle: selectTitle,
      placeholderValue: placeholderValue,
      textBoxClass: {
        class: "textBoxReadonly",
      },
      moreButton: {
        icon: "more",
        disabled: false,
        hint: "Выбрать значение",
        stylingMode: "text",
        elementAttr: {
          class: "moreButton",
        },
        onClick: () => {
          this.showPopUp();
        },
      },
      clearButton: {
        icon: "clear",
        disabled: false,
        focusStateEnabled: false,
        hoverStateEnabled: false,
        stylingMode: "text",
        hint: "Удалить значение",
        onClick: () => {
          this.clearValue();
        },
      },
      okButtonOptions: {
        text: "ОК",
        type: "default",
        onClick: () => {
          this.passValueToGet();
        },
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: () => {
          this.closePopUp();
        },
      },
    };
  },
});
