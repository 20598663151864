import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    class: "CreateEditDialog-textbox",
    value: _ctx.modelValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
    "display-expr": "desc",
    "data-source": _ctx.dataSource,
    readOnly: _ctx.readOnly,
    onValueChanged: _ctx.valueChanged,
    disabled: _ctx.disabled,
    stylingMode: _ctx.stylingMode
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxValidator, { "validation-group": _ctx.validationGroup }, {
        default: _withCtx(() => [
          (!_ctx.hasEmptyValue)
            ? (_openBlock(), _createBlock(_component_DxRequiredRule, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["validation-group"])
    ]),
    _: 1
  }, 8, ["value", "data-source", "readOnly", "onValueChanged", "disabled", "stylingMode"]))
}