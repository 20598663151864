export const enum PlanState {
  Created = 0,
  NotFound,
  Waiting,
  Running,
  Exception,
  Canceling,
  Canceled,
  Completed,
}

export const enum MarginUnitType {
  Percent = 0,
  CubicMeters,
}

export const enum DrawingSchemeDescriptionType {
  Label = 0,
  CargoName,
  Width,
  LabelAndWidth,
}
