import { createApp } from "vue";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { abilitiesPlugin } from "@casl/vue";
import setupInterceptors from "./services/setupInterceptors";
import { getAppAbility } from "./abilities/ability";
import "./css/dx.material.custom-scheme.css";
import "./css/stimulsoft.viewer.office2013.whiteblue.css";
import "./css/main.css";

const ability = getAppAbility();

setupInterceptors(store);
createApp(App)
  .use(store)
  .use(router)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  })
  .mount("#app");
