import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxFileUploader = _resolveComponent("DxFileUploader")!

  return (_openBlock(), _createBlock(_component_DxFileUploader, {
    accept: _ctx.accept,
    multiple: false,
    "upload-mode": "useForm",
    value: _ctx.files,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
    isValid: _ctx.isValid,
    "validation-error": _ctx.validationError,
    "allowed-file-extensions": _ctx.allowedFileExtensions,
    onValueChanged: _ctx.valueChanged,
    ref: "fileUploader"
  }, null, 8, ["accept", "value", "isValid", "validation-error", "allowed-file-extensions", "onValueChanged"]))
}