
import DxSelectBox from "devextreme-vue/select-box";
import { EnumProvider } from "@/logic/providers/enumProvider";
import { defineComponent } from "vue";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
  },
  emits: ["update:id", "update:desc", "changed"], //changed нужен для обновления в темплейтах
  props: {
    //поле в модели: идентификатор
    id: {},
    //поле в модели: отображаемое
    desc: {},
    disabled: { type: Boolean, required: false, default: false },
    enumType: { type: String, required: true, default: undefined },
    //допустимость выбора пустого значения
    hasEmptyValue: { type: Boolean, required: false, default: false },
    //идентификатор пустого значения
    emptyValueId: { type: Number, required: false, default: -1 },
    //наименование пустого значения
    emptyValueName: { type: String, required: false, default: "Не выбрано" },
    //отображаемое поле
    displayField: { type: String, required: false, default: "description" },
    //ключевое поле
    keyField: { type: String, required: false, default: "id" },
    //показатель возможности изменения значения
    readOnly: { type: Boolean, required: false, default: false },
    // стиль
    stylingMode: { type: String, required: false, default: "underlined" },
    // отображаемые операции
    displayedOperations: { type: Array, required: false, default: () => [] },
    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },
  },
  methods: {
    getDataSource: async function () {
      let enumValues;
      if (this.displayedOperations.length != 0) {
        enumValues = this.displayedOperations;
      } else {
        enumValues = await (this as any).enumProvider.getDataSource(
          (this as any).enumType
        );
      }
      const dataSource = enumValues.map((x: any) => {
        return {
          id: x[(this as any).keyField],
          desc: x[(this as any).displayField],
        };
      });
      if ((this as any).hasEmptyValue) {
        dataSource.unshift(this.emptyItem);
      }
      return dataSource;
    },
    getValueById(id: number) {
      return (this as any).dataSource.find((x: any) => x.id == id);
    },
    valueChanged(e: any) {
      if (e.value) {
        const id = e.value.id;
        const desc = e.value.desc;
        this.$emit("update:id", id);
        this.$emit("update:desc", desc);
        this.$emit("changed", { id, desc });
      }
    },
    updateModelValue() {
      if (this.id !== null) {
        this.modelValue = this.getValueById((this as any).id);
      } else this.modelValue = this.emptyItem;
    },
  },
  data() {
    return {
      enumProvider: new EnumProvider(),
      dataSource: null,
      modelValue: {},
      emptyItem: {
        id: null,
        desc: (this as any).emptyValueName,
      },
    };
  },
  watch: {
    id: {
      handler: async function () {
        this.updateModelValue();
      },
    },
    displayedOperations: async function (newVal) {
      if (newVal) {
        (this as any).dataSource = await (this as any).getDataSource();
        this.updateModelValue();
      }
    },
  },
  async created() {
    (this as any).dataSource = await (this as any).getDataSource();
    this.updateModelValue();
  },
});
