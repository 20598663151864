export class TableSelectionConfig {
  mode = ""; //'single', 'multiple'
  selectAllMode = ""; //'allPages', 'page'
  showCheckBoxesMode = ""; //'none', 'onClick', 'onLongTap', 'always'
  hoverStateEnabled = true;

  constructor(config: any) {
    Object.assign(this, config);
  }
}
