/* eslint-disable */
import {ColumnConfig, IColumnConfig} from './columnConfig';

export class NumberColumnConfig extends ColumnConfig {
  //размер шага при изменении кнопками
  step?: number;

  //минимальное значение
  min?: number;

  //максимальное значение
  max?: number;

  constructor(config: INumberColumnConfig) {
    //if (!config.editTemplateName) config.editTemplateName = 'numberTemplate';
    if (!config.defaultValue) config.defaultValue = null as Number | null;
    super(config);
    this.step = config.step;
    this.min = config.min;
    this.max = config.max;
  }
}

export interface INumberColumnConfig extends IColumnConfig {
  step?: number;
  min?: number;
  max?: number;
}
