
import DxDateBox from "devextreme-vue/date-box";
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    DxDateBox,
    DxValidator,
    DxRequiredRule,
  },
  emits: ["update:value", "changed"],
  props: {
    //режим контрола
    mode: { type: String, required: false, default: "date" },
    //из devExtreme значение
    value: { required: true },
    //использовать дату в utc
    isUtc: { required: false, default: true },
    //подсказка
    placeholder: { type: String, required: false, default: undefined },
    //показатель видимости контрола
    visible: { type: Boolean, required: false, default: true },
    //показатель валидации
    isValid: { type: Boolean, required: false, default: true },
    //показатель возможности изменения значения
    readOnly: { type: Boolean, required: false, default: false },
    //показатель возможности взаимодействия
    disabled: { type: Boolean, required: false, default: false },
    //подсказка при наведении на контрол
    hint: { type: String, required: false, default: undefined },
    //недопустимые даты
    disabledDates: { type: Object, required: false }, //Array<Date> | Function
    //отображаемый формат
    displayFormat: { type: String, required: false, default: undefined },
    //формат значения при сериализации для передачи на сервер
    dateSerializationFormat: { type: String, required: false, default: "" },
    //минимальное значение
    min: { type: Date, required: false, default: undefined },
    //максимальное значение
    max: { type: Date, required: false, default: undefined },
    //тип контрола для выбора значения
    //доступные типы:
    //'calendar' | 'list' | 'native' | 'rollers'
    pickerType: { type: String, required: false, default: "" },
    //тип значения
    type: { type: String, required: false, default: "date" },
    //управление пользовательским вводом на основе маски из displayFormat
    useMaskBehavior: { type: Boolean, required: false, default: false },
    //показавать кнопку удаления
    hasEmptyValue: { type: Boolean, required: false, default: false },
    // стиль
    stylingMode: { type: String, required: false, default: "underlined" },
    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },
    contentReady: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
    enterKey: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  methods: {
    valueChanged(e: any) {
      if (this.isUtc) e.value = this.toUtc(e.value);
      this.$emit("update:value", e.value);
      this.$emit("changed", e);
    },

    fromUtc(date: any) {
      if (!date) return date;
      if (date instanceof Date)
        return new Date(
          this.value.getTime() - this.value.getTimezoneOffset() * 60000
        ).toISOString();
      return DateTime.fromISO(date, { zone: "utc" })
        .toLocal()
        .toJSDate()
        .toISOString();
    },

    toUtc(date: any) {
      if (!date) return date;
      if (date instanceof Date)
        return DateTime.fromJSDate(date)
          .toUTC()
          .toJSDate()
          .toISOString()
          .replace("Z", "");
      return DateTime.fromISO(date)
        .toUTC()
        .toJSDate()
        .toISOString()
        .replace("Z", "");
    },
  },
  data() {
    return {
      modelValue: null,
      displayFormatValue: null,
      useMaskBehaviorValue: null,
      typeValue: null,
      modes: new Map([
        [
          "date",
          (e: any) => {
            e.displayFormatValue = "dd.MM.yyyy";
            e.useMaskBehaviorValue = true;
            e.typeValue = "date";
          },
        ],
        [
          "datetime",
          (e: any) => {
            e.displayFormatValue = "dd.MM.yyyy HH:mm";
            e.useMaskBehaviorValue = true;
            e.typeValue = "datetime";
          },
        ],
        [
          "time",
          (e: any) => {
            e.displayFormatValue = "HH:mm";
            e.useMaskBehaviorValue = true;
            e.typeValue = "time";
          },
        ],
      ]),
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.modelValue = this.isUtc ? this.fromUtc(value) : value;
      },
    },
  },
  created() {
    (this as any).modelValue = this.isUtc
      ? this.fromUtc(this.value)
      : this.value;

    (this as any).displayFormatValue = this.displayFormat;
    (this as any).typeValue = this.type;
    (this as any).useMaskBehaviorValue = this.useMaskBehavior;
    const func = this.modes.get(this.mode);
    if (func) func(this);
  },
});
