export enum FeatureType {
  Boolean = 0,
  Integer = 1,
  Decimal = 2,
  String = 3,
  DateTime = 4,
  Enum = 5,
  DCatalogue = 6,
  Catalogue = 7,
  DataField = 8,
}

export enum ImportColumnType {
  Field = 0,
  Property = 1,
}
