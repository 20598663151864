export default class User {
  public username: string;

  public userfullname: string;

  public password: string;

  public rememberMe: boolean;

  constructor(
    username: string,
    userfullname: string,
    password: string,
    rememberMe: boolean
  ) {
    this.username = username;
    this.userfullname = userfullname;
    this.password = password;
    this.rememberMe = rememberMe;
  }
}
