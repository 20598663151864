import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dcbb2dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadSelectControl = _resolveComponent("UploadSelectControl")!
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!
  const _component_DxScrollView = _resolveComponent("DxScrollView")!
  const _component_DxToolbarItem = _resolveComponent("DxToolbarItem")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_openBlock(), _createBlock(_component_DxPopup, {
    visible: _ctx.popupVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.popupVisible) = $event)),
    "drag-enabled": true,
    "close-on-outside-click": false,
    "show-title": true,
    width: 600,
    height: 350,
    shading: true,
    resizeEnabled: false,
    title: "Выберите файл для импорта"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxScrollView, {
        width: "100%",
        height: "100%"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_UploadSelectControl, {
                value: _ctx.files,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
                allowedFileExtensions: _ctx.extensions,
                baseValidationError: {
              message: 'Выберите файл с данными для импорта',
            },
                ref: "uploadSelectControl"
              }, null, 8, ["value", "allowedFileExtensions"]),
              _createElementVNode("div", null, [
                _createVNode(_component_DxCheckBox, {
                  class: "ImportDialog-isDataReplacedCheckbox",
                  value: _ctx.isDataReplaced,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDataReplaced) = $event)),
                  text: "Заменить данные в таблицах"
                }, null, 8, ["value"])
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_DxToolbarItem, {
        options: _ctx.importButtonOptions,
        widget: "dxButton",
        location: "after",
        toolbar: "bottom"
      }, null, 8, ["options"]),
      _createVNode(_component_DxToolbarItem, {
        options: _ctx.cancelButtonOptions,
        widget: "dxButton",
        location: "after",
        toolbar: "bottom"
      }, null, 8, ["options"])
    ]),
    _: 1
  }, 8, ["visible"]))
}