
import { defineComponent } from "vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import TextControl from "@/components/controls/base/TextControl.vue";
import axios from "axios";
import TokenService from "@/services/tokenService";
import api from "@/services/api";
import { DateTime } from "luxon";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    TextControl,
  },
  emits: ["passCallbacks"],
  props: {
    controller: { required: true },
    exportConfig: { required: true },
  },
  methods: {
    show(visible: boolean) {
      this.popupVisible = visible;
      this.fileName = `${
        this.exportConfig.exportFileName
      }-${DateTime.now().toFormat("HH-mm-MM-dd-yyyy")}`;
    },
    async onExport(/*e: any*/) {
      /*let isValid = true;
      if (e.validationGroup) {
        const res = e.validationGroup.validate();
        isValid = res.isValid;
      }*/
      //if (isValid) { //TODO: Расскоментировать когда будет решена проблема
      this.$emit("passCallbacks", {
        file: this.fileName,
        startCallbackAsync: async (
          file,
          jobId,
          idParent,
          controller,
          importExportConfig
        ) => {
          const formData = new FormData();
          formData.append("fileName", file);
          formData.append("jobId", jobId);

          const params = {};
          if (idParent) {
            params["IdParent"] = idParent;
          }
          await axios
            .create({ baseURL: "/" })
            .post(`${controller}/${importExportConfig.startMethod}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + TokenService.getLocalAccessToken(),
              },
              params,
            });
        },
        completeCallbackAsync: async (file, jobId, controller) => {
          const { data } = await api.get(`${controller}/GetExportFile`, {
            responseType: "blob",
            params: {
              fileName: file,
              jobId: jobId,
            },
          });
          let blob = new Blob([data], { type: "application/octet-stream" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${file}.xlsx`;
          link.click();
          link.remove();
        },
      });
      this.show(false);
      //}
      return;
    },
    onCancel(): void {
      this.show(false);
      return;
    },
  },
  data() {
    return {
      exportButtonOptions: {
        text: "Экспорт",
        onClick: this.onExport,
        validationGroup: "exportValidationGroup",
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: this.onCancel,
      },
      popupVisible: false,
      fileName: "",
    };
  },
});
