
/* eslint-disable */
import { defineComponent } from "vue";
import {
  DxDataGrid,
  DxEditing,
  DxPopup,
  DxPosition,
  DxForm,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxPaging,
  DxPager,
  DxScrolling,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxToolbarItem,
  DxMasterDetail,
  DxColumnChooser,
  DxStateStoring,
  DxSummary,
  DxTotalItem,
  DxExport
} from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";
import { ColumnConfig } from "@/components/controls/catalogueGrid/columns/columnConfig";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { TableImportConfig } from "@/components/controls/catalogueGrid/tableImportConfig";
import { TableExportConfig } from "@/components/controls/catalogueGrid/tableExportConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import TextControl from "@/components/controls/base/TextControl.vue";
import TextAreaControl from "@/components/controls/base/TextAreaControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import DateControl from "@/components/controls/base/DateControl.vue";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import DefinePasswordControl from "@/components/controls/DefinePasswordControl.vue";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { CatalogueColumnConfig } from "./columns/catalogueColumnConfig";
import DataFillDialog from "@/components/controls/catalogueGrid/DataFillDialog.vue";
import { EnumColumnConfig } from "./columns/enumColumnConfig";
import router from "@/router";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import ExportWorkflow from "@/components/workflows/importExport/exportWorkflow/ExportWorkflow.vue";
import ImportWorkflow from "@/components/workflows/importExport/importWorkflow/ImportWorkflow.vue";
import { confirm } from 'devextreme/ui/dialog';
import CopyWorkflow from "@/components/workflows/copy/copyWorkflow.vue";
import {DateTime} from "luxon";
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
//import CatalogueSelectTextBox from "@/components/controls/base/CatalogueSelectControlPair/CatalogueSelectTextBox.vue"
//import CatalogueSelectDialog from "@/components/controls/base/CatalogueSelectControlPair/CatalogueSelectDialog.vue"


export default defineComponent({
  components: {
    ImportWorkflow,
    ExportWorkflow,
    CatalogueSelectControl,
    //CatalogueSelectTextBox,
    //CatalogueSelectDialog,
    DateControl,
    DxDataGrid,
    DxEditing,
    DxPopup,
    DxPosition,
    DxForm,
    DxItem,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxStateStoring,
    DxPaging,
    DxPager,
    DxScrolling,
    DxFilterPanel,
    DxFilterBuilderPopup,
    TextControl,
    TextAreaControl,
    CheckBoxControl,
    IntegerControl,
    DecimalControl,
    EnumControl,
    DxToolbarItem,
    DefinePasswordControl,
    DxMasterDetail,
    DataFillDialog,
    DxColumnChooser,
    DxSummary,
    DxTotalItem,
    CopyWorkflow,
    DxExport,
  },
  mixins: [mountComponentMixin],
  props: {

    onUpdateParentModelCallback: {required: false},

    //список элементов тулбара
    toolbarItems: {required: false},

    //компонента диалогового окна создания\редактирования записи
    popupCreateEditComponent: {required: false},

    //компонента диалогового окна информации о таблице
    popupInfoComponent: {required: false},

    //модель родительского элемента
    parentModel: {required: false},

    onUpdatedCallback: {required: false},
    onInsertedCallback: {required: false},
    onRowRemovedCallback: {required: false},

    //список конфигураций колонок таблицы - обязательный
    columns: { required: true, type: Array }, //ColumnConfig[]
    //объект конфигурации возможностей редактирования таблицы (CRUD) - обязательный
    editingConfig: { required: true, type: TableEditingConfig }, //TableEditingConfig

    //объект конфигурации возможностей импорта
    importConfig: { required: false, type: TableImportConfig }, //TableImportConfig

    //объект конфигурации возможностей экспорта
    exportConfig: {required: false, type: TableExportConfig}, //TableExportConfig

    summaryConfig: {required: false},

    //объект конфигурации режима выбора строк в таблице - обязательный
    selectionConfig: { required: true, type: TableSelectionConfig },
    //объект конфигурации внешнего вида таблицы
    designConfig: { required: true, type: TableDesignConfig },
    //наименование контроллера, к которому подключается таблица в серверном режиме
    controller: { required: false, type: String },

    showPageSizeSelector: { required: true, type: Boolean },

    pageSize: { required: true, type: Number },

    pageSizes: { required: false }, //number[]

    filterRowVisible: { required: true, type: Boolean },

    getSelectedData: { type: Function },

    changeSelectedData: { type: Function },

    headerFilterVisible: { required: true, type: Boolean },

    groupPanelVisible: { required: true, type: Boolean },

    filterPanelVisible: { required: true, type: Boolean },

    filterBuilder: { required: false, type: Object },

    filterValue: { required: false, type: Array },

    keyExpr: { required: false, type: String },

    selectMode: { required: false },

    pushDataToSelectControl: { required: false, type: Boolean, default: false},

    additionalFilters: {required: false},

    customLoadUrl: { required: false },

    customParentId: { required: false },

    customParams: { required: false },

    customStorageKey: { required: false, type: String },
  },
  methods: {
    onCellPrepared(e: any){
      if(e.rowType == "header")
        e.cellElement.style.textAlign = "center";
    },

    resetSelectedRows(){
      if (this.editingConfig.allowResetSelectedRows && this.dxGrid.getSelectedRowKeys){
        let selectedRowsKeys = this.dxGrid.getSelectedRowKeys();
        for (var i = 0; i < selectedRowsKeys.length; i++) {  
          this.dxGrid.deselectRows([selectedRowsKeys[i]]);  
        }
      }
      this.$refs.area.blur()
    },

    onExporting(e: any){
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      let fileName = this.editingConfig.exportedFileName + '.xlsx';
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function() {
        workbook.xlsx.writeBuffer()
          .then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
          });
      });
      e.cancel = true;
    },

    onEditorPreparing(e: any){
      if (e.parentType == 'filterRow') {  
        e.editorOptions.onEnterKey = () => {  
          let button = e.component._$element.find(".dx-apply-button");
          if (button)
            button[0].click(); 
        } 
      } 
    },

  getEditableColumns() {
    return this.columns.filter((x: any) => x.editVisible && !x.readOnly);
  },

  onDetailMounted(data:any){
    this.$nextTick(function() {
        this.mountDetail(data);
    });
  },

  mountDetail(data: any){
      let model = data.data;
      if(this.parentModel){
          model.idParent = this.parentModel.id;
      }
       this.mountComponent(this.editingConfig.detailComponentName, `detailDomId_${model.id}_${this.dxGridRenderKey}`, {
          parentModel: { ...model },
          parentEditingConfig: this.editingConfig,
          updateParentModelCallback: () => {
              if(this.onUpdateParentModelCallback)
                this.onUpdateParentModelCallback();
              this.dxGrid.refresh();
            }
        });
  },

  onRowExpanding(e: any) {
    /*e.component.byKey(e.key).then((model:any)=>{
        this.mountDetail(model)
    });*/
  },

  //получение значения ячейки таблицы для связанного с ней контролла редактирования
  getCellValue(cellInfo: any) {
    if (cellInfo.value === 0) return 0;

    if (cellInfo.value) return cellInfo.value;
    const columnData = this.getColumnData(cellInfo.column.dataField);
    return columnData.defaultValue;
  },

  //для синхронизации контрола в шаблоне enum и таблицы
  onEnumTemplateValueChanged(e: any, cellInfo: any){
      const modelDescField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as EnumColumnConfig).modelDescField;

      const modelField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as EnumColumnConfig).modelField;

      if(e.id != null){
        cellInfo.data[modelField] = e.id;
        cellInfo.data[modelDescField] = e.desc;
        cellInfo.setValue(e.id);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, e.desc);

        //Для обхода бага
        this.selectTransferObj[modelField] = e.id;
      }else{
          cellInfo.data[modelField] = null;
          cellInfo.data[modelDescField] = '';
          cellInfo.setValue(null);
          (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, '');

          //Для обхода бага
          this.selectTransferObj[modelField] = null;
      }
  },

    //для синхронизации контрола в шаблоне catalogue и таблицы
  onCatalogueTemplateValueChanged(e: any, cellInfo: any) {
    if (cellInfo.setValue) {
      const modelDescField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as CatalogueColumnConfig).modelDescField;

      const modelIdField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as CatalogueColumnConfig).modelIdField;

      if (e.id != null) {
        cellInfo.data[modelIdField] = e.id;
        cellInfo.data[modelDescField] = e.desc;
        cellInfo.setValue(e.id);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelIdField, e.id);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, e.desc);

        //Для обхода бага
        this.selectTransferObj[modelIdField] = e.id;
      } else {
        cellInfo.data[modelIdField] = null;
        cellInfo.data[modelDescField] = '';
        cellInfo.setValue(null);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelIdField, null);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, '');

        //Для обхода бага
        this.selectTransferObj[modelIdField] = null;
      }

      this.columnUpdate(cellInfo);
    }
  },

  updateAdditionalFilters(additionalFilters: any){
    this.storeManager.setAdditionalFilters(this.dxGridRenderKey, additionalFilters);
    this.dxGrid.refresh();
  },

    onInitNewRow(e: any){
       if(this.editingConfig.mode == 'cell' || this.editingConfig.mode == 'row'){
          e.promise = (this as any).dataSource.getCreate().then((model: any) => {
            this.storeManager.setCreateModel(this.dxGridRenderKey, model);
            e.data = model;
          });
       }
       this.$emit('init-new-row');
    },

    // Обработчик изменения значение в CatalogueSelectControl в режиме редактирования Cell
    onCellCatalogueTemplateSelect(cellInfo: any){
        throw "NotImplemented";
        /*const dialog = (this.$refs.catalogueSelectDialog as any);
        const keyField = this.getColumnData(cellInfo.column.dataField).keyField;
        const displayField = this.getColumnData(cellInfo.column.dataField).displayField;
        const catalogueComponentName = this.getColumnData(cellInfo.column.dataField).catalogueComponentName;
        dialog.show(displayField, keyField, catalogueComponentName, cellInfo);*/
    },

    // Обработчик изменения значения при выборе из CatalogueSelectControl в режиме редактирования Cell
    onCellCatalogueTemplateChanged(e: any){
      throw "NotImplemented";
      /*const cellInfo = e.cellInfo;
      if (cellInfo.setValue) {
          const modelIdField = (this.getColumnData(
            cellInfo.column.dataField,
          ) as CatalogueColumnConfig).modelIdField;
          let obj = {[modelIdField]: null};
          if (e.id) {
            obj = {[modelIdField]: e.id}
          }
          const dataSource = (this as any).dataSource;

          if(cellInfo.data.id){
            this.storeManager.setEditModel(this.dxGridRenderKey, {...cellInfo.data});
            dataSource.update(cellInfo.data.id,obj).then(() => {
                cellInfo.component.refresh();
              });
          }else{
            const dataSource = (this as any).dataSource;
            dataSource.getCreate().then((model: any) => {
              this.storeManager.setCreateModel(this.dxGridRenderKey, {...model});
               dataSource.insert(obj).then(() => {
                cellInfo.component.refresh();
                });
            });
          }
      }*/
    },

    onRowUpdating(e: any){
      if(this.editingConfig.mode == 'cell' || this.editingConfig.mode == 'row'){
        e.newData = {...e.newData,...this.selectTransferObj};
        this.storeManager.setEditModel(this.dxGridRenderKey, {...e.oldData});
      }
    },

    onRowUpdated(e: any){
      this.selectTransferObj = {};
      if(this.onUpdatedCallback)
          this.onUpdatedCallback();
      this.$emit('row-updated',e);
    },

    onRowInserting(e: any){
       if(this.editingConfig.mode == 'cell' || this.editingConfig.mode == 'row'){
          e.data = {...e.data,...this.selectTransferObj};
       }
    },

    onRowInserted(e: any){
      this.selectTransferObj = {};
      if(this.onInsertedCallback)
          this.onInsertedCallback();
      this.$emit('row-inserted',e);
    },

    onRowRemoved(e: any){
      if(this.onRowRemovedCallback)
          this.onRowRemovedCallback();
      this.$emit('row-removed',e);
    },

    onEditingStart(e: any){
      this.editedRow = e.data.id
      this.$emit('editing-start');
    },

    onEditCanceling(){
      this.editedRow = ""
      this.$emit('edit-canceling');
    },

    saving(){
      this.editedRow = ""
      this.$emit('saving');
    },

    onRowValidating(e){
      this.$emit('row-validating', e);
    },

    onOptionChanged(e: any){
      // Только при изменении фильтров
      if (e.name == "columns" && e.fullName.includes("filterValue")){

        this.isFilter = false;
        for (let index = 0; index < this.columns.length; index++){
          if (e.component.columnOption(index, 'filterValues') || e.component.columnOption(index, 'filterValue')){
            this.isFilter = true;
            break;
          }
        }
        this.dxGrid.repaint();

        let cells = this.dxGrid._$element.find("td");
        for (let index = 0; index < this.columns.length; index++){
          let element = cells[0].className.includes("dx-datagrid-group-space") ? cells[index + 1] : cells[index];
          if (e.component.columnOption(index, 'filterValues')){
            element.style.fontWeight = "700";
            element.style.color = "#363B5D";
          }
          else{
              element.style.fontWeight = "";
              element.style.color = "";
          }
        }
      }
    },

    onRowPrepared(e: any) {
      this.$emit('row-prepared',e);
      if (e.rowType == "header"){
        let index = 0;
        e.columns.forEach((column: any) => {
          if(column.filterValues){
            e.cells[index].cellElement.style.fontWeight = "700";
            e.cells[index].cellElement.style.color = "#363B5D";
          }
          index++;
        })
      }
    },

    //метод для получения отображаемого значения для description колонок, если они видны
    getColumnDisplayValueFunc(column: any) :any {
      if(column.customDisplayCallback){
        return (rowData: any) => {
          return column.customDisplayCallback(rowData[column.dataField]);
        };
      }

      if (column.descriptionColumnName) {
        return (rowData: any) => {
          return rowData[column.descriptionColumnName];
        };
      }
      if (column.editTemplateName === "dateTimeTemplate") {
        return (rowData: any) => {
          const value = rowData[column.dataField];
          if(!value) return "";
     
          const date = column.isUtc ? DateTime.fromISO(value, { zone: "utc"}).toLocal() : DateTime.fromISO(value).toLocal();
          if(column.mode == "datetime")
            return date.toFormat("dd.MM.yyyy HH:mm");
          return date.toFormat("dd.MM.yyyy");
        };
      }
      if (column.editTemplateName === "textBooleanDateTimeTemplate") {
        return (rowData: any) => {
          const value = rowData[column.dataField];
          const valueBoolean = rowData[column.dataFieldBoolean];
          const valueDateTime = rowData[column.dataFieldDateTime];
          if (valueBoolean != null)
            return valueBoolean;
          else if (valueDateTime != null)
            return valueDateTime;
          else
            return value;
        };
      }
      return undefined;
    },

    getColumnDisabled(cellInfo: any) : any {
      const column = this.getColumnData(cellInfo.column.dataField);
      if (column.disabledAction && cellInfo)
        return column.disabledAction(cellInfo.row.data) || column.disabled;
      return column.disabled;
    },

    getColumnData(dataField: string) : any {
      return ((this as any).columns as any).filter(
        (x: ColumnConfig) => x.dataField == dataField
      )[0];
    },

    //при изменении содержимого
    onContentReady(e: any) {
      
      if (this.firstDisplay){
        let button = e.component._$element.find("#clearFilterButton");
        if (button.length != 0){
          if (e.component.getCombinedFilter()){
            button[0].style.visibility = "visible";
            this.isFilter = true;
          }
          else {
            button[0].style.visibility = "hidden";
          }
        }
        this.firstDisplay = false;
      }
      
      /* Undocumented !!! */
      const columnChooserView = e.component.getView("columnChooserView");  
      if (columnChooserView && !columnChooserView._popupContainer) {  
          columnChooserView._initializePopupContainer();  
          columnChooserView.render();  
          columnChooserView._popupContainer.option("position", { of: document.body, my: "right bottom", at: "right bottom", offset: "0 -100"});  
      }  

      (this as any).dxGrid = e.component;
      this.refreshDataKey = this.refreshDataKey + 1;
      if (Array.isArray((this as any).dataSource)) {
        ((this as any).dxGrid as any).keyExpr = (this as any).keyExpr;
      }

      if (this.dxGrid.totalCount)
        this.totalCount = this.dxGrid.totalCount();
      else this.totalCount = null;

      (this as any).$emit("content-ready", e);
    },

    onRowClick(e: any) {
      // Возможно event.stopPropagation потребуется в onRowClick (т.к. он вызывается в master при вызове в detail)
      // но тогда rowDblClick из dev-extreme не будет работать!

      (this as any).$emit("row-click", e);
      if ((this as any).changeSelectedData && !e.data.collapsedItems)
        (this as any).changeSelectedData(((this as any).dxGrid as any).getSelectedRowsData());
    },

    onRowDblClick(e: any) {
      //предотвращаем вызов события в master таблице, если событие из detail таблицы
      e.event.stopPropagation();

      (this as any).$emit("row-dblclick", e);
      if ((this as any).getSelectedData && !e.data.collapsedItems)
        (this as any).getSelectedData(((this as any).dxGrid as any).getSelectedRowsData());
        
      if(!this.selectMode && this.editingConfig.allowUpdating && this.editingConfig.rowDblClickEditing){
        if(this.editingConfig.mode == 'popup')
          this.editRow({row: e});
        else
          this.dxGrid.editRow(e.rowIndex);
      }
    },

    dataFillDialog(){
      return this.$refs.dataFillDialog as any;
    },

    startImportWorkflow(){
      return (this.$refs.importWorkflow as any).start(this.parentModel?.id);
    },

    onImportClose(){
      this.$emit('onImportClose');
      this.dxGrid.refresh();
    },

    onDataFillApply(){
      this.$emit('onDataFillApply');
    },

    startExportWorkflow(){
      return (this.$refs.exportWorkflow as any).start(this.parentModel?.id);
    },

    deleteSelectedRows() {
      let result = confirm("Вы уверены, что хотите удалить выбранные записи?", "Подтверждение действия");
      result.then((dialogResult: any) => {
        if(!dialogResult)
          return;
        let selectedRowsKeys = this.dxGrid.getSelectedRowKeys();
        selectedRowsKeys.forEach((key: any) => {
          this.dataSource.remove(key).then(() => {
            this.dxGrid.refresh();
          })
        })
      })
    },

    changeScrollSettings(){
      this.designConfig.horizontalScroll = !this.designConfig.horizontalScroll
      this.$emit('remember-scroll-settings', this.designConfig.horizontalScroll);
    },

    async showDataFillDialog(){
      let filterExpr = this.dxGrid.getCombinedFilter();
      let gridDataSource = this.dxGrid.getDataSource();
      const {data} = await gridDataSource.store().load({
        filter: filterExpr
      });
      let selectedRowsData = data;
      return this.dataFillDialog().show(true, selectedRowsData);
    },

    showInfoDialog(){
      return this.popupInfo().open();
    },

    //установка режима соединения с сервером
    setRemoteOperationsMode() {
        (this as any).remoteOperations = {
          groupPaging: true,
          paging: true,
          filtering: true,
          sorting: true,
          grouping: true,
          summary: true,
        };
        (this as any).serverCrud = true;
    },

    popupCreateEdit(){
      return this.$refs.popupCreateEdit as any;
    },

    popupInfo(){
      return this.$refs.popupInfo as any;
    },

    onSelectionChanged(e: any){
      this.selectedRowsData = e.selectedRowsData;
      this.$emit('selection-changed', e);
    },

    clearFilter(){
      this.dxGrid.clearFilter("row");
      this.dxGrid.clearFilter("header");
      this.isFilter = false;
      this.dxGrid.repaint();
    },

    /*Toolbar таблицы кастомизируется с помощью изменения объекта toolbarOptions*/
    onToolbarPreparing(e: any): void {
      //удаляем стандартную кнопку добавления
      e.toolbarOptions.items = e.toolbarOptions.items.filter(
        (x: any) => x.name != "addRowButton"
      );

      let index = e.toolbarOptions.items.findIndex((x: any) => x.name == "applyFilterButton")
      if (index != -1)
        e.toolbarOptions.items[index].visible = false;

       if (this.editingConfig.showInfo) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'info',
              hint: 'Справка',
              onClick: this.showInfoDialog.bind(this)
            },
          });
      }

      if (this.editingConfig.allowDataFill) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'decreaseindent',
              hint: 'Заполнение данных',
              onClick: this.showDataFillDialog.bind(this)
            },
          });
      }

      if (this.editingConfig.allowDeleteMultipleRows) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'trash',
              hint: 'Удаление выбранных записей',
              onClick: this.deleteSelectedRows.bind(this)
            },
          });
      }

       if (this.editingConfig.allowClearFilter) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            text: "Очистить фильтр",
            type: "default",
            stylingMode: "text",
            onClick: this.clearFilter.bind(this),
            elementAttr: {
              style: this.isFilter ? "visibility: visible" : "visibility: hidden",
              id: "clearFilterButton",
            },
          },
        });
      }

      if(this.toolbarItems){
        this.toolbarItems.forEach((x:any) => {
           e.toolbarOptions.items.unshift(x);
        });
      }

      if (this.editingConfig.allowChangeScrollSettings) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            name: "horizontalScrollButton",
            icon: "aligncenter",
            hint: "Изменение ширины таблицы",
            stylingMode: "text",
            onClick: this.changeScrollSettings,
          }
        });
      }

      if (this.editingConfig.allowExport) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "download",
            hint: "Экспорт данных",
            stylingMode: "text",
            onClick: this.startExportWorkflow.bind(this),
          },
        });
      }

      if (this.editingConfig.allowImport) {
          e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'upload',
              hint: 'Импорт данных',
              onClick: this.startImportWorkflow.bind(this),
            },
          });
      }

      if (this.editingConfig.allowDeleteAll) {
        const title = this.editingConfig.deleteAllTitle;
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            text: title ? title : "Удалить все",
            stylingMode: "outlined",
            onClick: this.deleteAll.bind(this),
          },
        });
      }

      if (this.editingConfig.allowAdding) {
        e.toolbarOptions.items.unshift({
          name: "addButton",
          location: "before",
          widget: "dxButton",
          options: {
            text: "Добавить",
            type: "default",
            stylingMode: "outlined",
            onClick: this.createNewRow.bind(this),
          },
        });
      }
      this.$emit('onToolbarPreparing',e);
    },

    async deleteAll() {
       //TODO: обработка ошибок, переписать на await
      let result = confirm("Вы уверены, что хотите удалить ВСЕ записи из таблицы?", "Подтверждение действия");
      result.then((dialogResult: any) => {
          if(!dialogResult)
            return;
            this.dataSource.removeAll().then(()=>{
                this.dxGrid.refresh();
                if(this.onRowRemovedCallback)
                    this.onRowRemovedCallback();
                this.$emit("remove-all-rows", {component: this.dxGrid})
            });
      });
     
    },

    createNewRow(): void {
      if(this.editingConfig.pageEditing){
        const dataSource = (this as any).dataSource;
        dataSource.getCreate().then((model: any) => {
          this.storeManager.setCreateModel(this.dxGridRenderKey, {...model});
          dataSource.insert({...model}).then((response) => {
              router.push({
                  name: this.editingConfig.pageName,
                  params: {id: `${response.data.id}`},
              });
          });
        });
        return;
      }

      if(this.editingConfig.mode == 'popup'){
          const dataSource = (this as any).dataSource;
          dataSource.getCreate().then((model: any) => {
            this.storeManager.setCreateModel(this.dxGridRenderKey, {...model});
            this.popupCreateEdit().open({...model},'create')
          });
      }else{
          const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
          const dataSource = (this as any).dataSource;
          dataSource.getCreate().then((model: any) => {
             component.addRow({...model});
          });
      }
    },

    editRow(e: any): void {
      if(this.editingConfig.pageEditing){
        router.push({
                  name: this.editingConfig.pageName,
                  params: {id: `${e.row.key}`},
              });
        return;
      }
      
      const dataSource = (this as any).dataSource;
      dataSource.getEdit(e.row.key).then((model: any) => {
        this.storeManager.setEditModel(this.dxGridRenderKey, {...model});
        this.popupCreateEdit().open({...model},'edit')
      });
    },

    deleteRow(e: any): void {
      const editRowIndex = e.component.getRowIndexByKey(e.row.key);
      e.component.deleteRow(editRowIndex);
    },

    copyRow(e: any): void{
      const copyRowIndex = e.component.getRowIndexByKey(e.row.key);
      let copyRow = {...e.row.data};
      (this.$refs.copyWorkflow as any).start(copyRow.id, this.parentModel?.id)
      return ;
    },

    onCopyClose(e: any): void{
      this.dxGrid.refresh();
    },


    async popupCreateEditSaveModelCallback(model:any, popup:any, editType: any) {


      const dataSource = (this as any).dataSource;
      const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;

      if(editType === 'create'){
        await dataSource.insert({...model});  
        this.selectTransferObj = {};
        if(this.onInsertedCallback)
          this.onInsertedCallback();
        this.$emit('row-inserted',{component});
      }
    
      if(editType === 'edit'){
        await dataSource.update(model.id,{...model});
        this.selectTransferObj = {};
        if(this.onUpdatedCallback)
          this.onUpdatedCallback();
        this.$emit('row-updated',{component});
      }
      component.refresh();
      popup.close();
    },

    exportToExcel(): void {
      const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
      component.exportToExcel();
    },

      //для синхронизации темплейтов редатирования строки и таблицы
    columnUpdate(cellInfo: any) {
      const column = this.getColumnData(cellInfo.column.dataField);
      column.repaintControls?.forEach((x:any) => {
        (this as any).forceKeys[x] += 1;
      });
    },

    //для синхронизации темплейтов редатирования строки и таблицы
    onTemplateValueChanged(e: any, cellInfo: any) {
      if (cellInfo.setValue) cellInfo.setValue(e.value);
      this.columnUpdate(cellInfo);
    },
  },
  computed: {
    async catalogueSelectControlFilters() {
      this.refreshDataKey;
      if(this.pushDataToSelectControl && this.dxGrid){
        /*
          TODO: в данный момент механизм отбора уже выбранных значений работает на стороне клиента
          т. е. ситуация -
          есть таблица ролей у пользователя и мы добавляем пользователю роль,
          но мы не хотим в select-окне видить уже добавленные пользователю роли
          сейчас все добавленные пользователю роли достаются с сервера на клиент и передаются
          фильтром в select-окно. Это может быть медленно при большом кол-ве ролей у пользователя.
        
          Альтернативное решение делать собственные фильтры и передавать id пользователя в запрос загрузки всех ролей
        */
        const {data} = await this.dxGrid.getDataSource().store().load();
        const items = this.dxGrid.getDataSource().items();
        const mergeArray = [...items, ...data];
        const key = "id";
        const distinctItems =[...new Map(mergeArray.map(item => [item[key], item])).values()];
        return distinctItems;
      }
      else return [];
    },
    tableInstance(): any {
      return ((this as any).$refs[(this as any).refTable] as DxDataGrid)
        .instance;
    },
    storageKey(): any {
      if (this.customStorageKey)
        return this.customStorageKey;
      if (!this.selectMode)
        return `${this.controller}Storage`;
      else 
        return `${this.controller}StorageSelectMode`;
    },
    dxPagerVisible(): any {
      if(!this.totalCount || !this.pageSizes)
        return false
      const rowCount = this.totalCount;
      const minSize = Math.min(...this.pageSizes)
      return minSize < rowCount && this.showPageSizeSelector
    }
  },
  data() {
    return {
      totalCount: null,
      firstDisplay: true,
      isFilter: false,
      editedRow: "",
      selectedRowsData: [],
      selectTransferObj: {}, //Объект для временного хранения значений при выборе из селект контрола (в row режиме)
      forceKeys: {},
      guidHelper: new GuidHelper(),
      dxGridRenderKey: "",
      dataSource: null,
      remoteOperations: null,
      storeManager: new StoreManager(),
      dxGrid: {},
      serverCrud: false,
      entityModel: {},
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      editButtons: [],
      refTable: "refTable",
      cancelButtonOptions: {
        text: "Отмена",
        onClick: () => {
          if (this.tableInstance) {
            (this.tableInstance as any).cancelEditData();
          }
        },
      },
      refreshDataKey: 1,
    };
  },

  created() {
    this.dxGridRenderKey = this.guidHelper.getUniqueId(4);

    //установка parent модели
    this.storeManager.setParentModel(this.dxGridRenderKey, {...(this as any).parentModel}, this.customParentId);
    
    //создание серверного хранилища данных
    (this as any).dataSource = this.storeManager.getStandartGridCrudStore(
      (this as any).controller,
      this.dxGridRenderKey,
      this.customLoadUrl ? this.customLoadUrl : "GetAll",
      this.customParams,
    );
    this.setRemoteOperationsMode();
    

    //инициализируем ключи обновления контролов шаблонов
    this.columns.forEach( (x: any) => {
      (this as any).forceKeys[x.dataField] = 0;
    });

    // TODO - убрать, когда будет ясно, используем scroll или paging
    this.selectionConfig.selectAllMode = this.designConfig.pagingMode == "scroll" ?  "page" : "allPages";
    //задание кнопок, editingConfig не доступен при создании в data
    this.editButtons = [{ hint: "Редактировать", icon: "edit", onClick: this.editRow },
        { hint: "Удалить", icon: "trash", onClick: this.deleteRow },
        { hint: "Скопировать", icon: "copy", onClick: this.copyRow, visible: this.editingConfig.allowCopy}]
    if(this.editingConfig.mode == 'row')
      this.editButtons = [{ name: "edit" },
          { name: "delete" },
          { hint: "Скопировать", icon: "copy", onClick: this.copyRow, visible: this.editingConfig.allowCopy}]

    //установка доплнительных фильтров
    this.storeManager.setAdditionalFilters(this.dxGridRenderKey, (this as any).additionalFilters);
  },
});
