export class TableDesignConfig {
  //ширина-высота popUp формы добавления\редактирования
  public popUpWidth = "550px";
  public popUpHeight = "auto";

  //количество колонок в форме добавления\редактирования
  //TODO: В будущем если понадобится можно использовать массив colCountByScreen (в нем в зависимости от размера экрана)
  public popUpColumnsCount = 1;

  //ширина-высота таблицы
  public width = "100%";
  public height = "100%";

  //минимальная ширина колонок таблицы
  public columnMinWidth?: number = undefined;

  //режим страниц или прокрутки (scroll/paging)
  public pagingMode?: string = "paging";
  //высота таблицы при режиме скролл
  public heightScroll?: string = "100%";

  public horizontalScroll?: boolean = true;

  constructor(config: any) {
    Object.assign(this, config);
  }
}
