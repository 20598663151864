export class TableImportConfig {
  public startMethod = "StartImport";
  public stopMethod = "StopImport";
  public progressMethodName = "";
  public progressEventName = "";
  public messageEventName: string | null = null;

  constructor(config: any) {
    Object.assign(this, config);
  }
}
