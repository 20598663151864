export const enum ProgressStatus {
  InProgress = 0,
  Completed = 1,
  Stopped = 2,
}

export const enum MessageStatus {
  Message = 0,
  Error = 1,
  Warning = 2,
}
