
import { defineComponent } from "vue";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import planTransportSettingsItemCreateEditDialog from "./PlanTransportSettingsItemCreateEditDialog.vue";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import TextControl from "@/components/controls/base/TextControl.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DxButton from "devextreme-vue/button";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import { DxCheckBox } from "devextreme-vue/check-box";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
    TextControl,
    FontAwesomeIcon,
    DxButton,
    IntegerControl,
    DxCheckBox,
  },
  props: {},
  computed: {
    planTransportSettingsItemCreateEditDialog() {
      return planTransportSettingsItemCreateEditDialog;
    },
  },
  methods: {
    onSave() {
      this.saving = true;
      this.storeManager.setEditModel(this.renderKey, { ...this.model });
      this.dataSource.update(this.model.id, { ...this.model }).then(() => {
        this.saving = false;
        this.savedModel = { ...this.model };
        this.haveChanges();
      });
    },
    haveChanges() {
      if (!this.loading)
        this.changes = !(
          JSON.stringify(this.savedModel) == JSON.stringify(this.model)
        );
    },
  },
  created() {
    this.renderKey = this.guidHelper.getUniqueId(4);
    this.dataSource = this.storeManager.getStandartGridCrudStore(
      "PlanTransportImportSettings",
      this.renderKey
    );
  },
  mounted() {
    this.loading = true;
    this.dataSource.getEdit(this.$route.params.id).then((model: any) => {
      this.model = model;
      this.loading = false;
      this.savedModel = { ...this.model };
      this.haveChanges();
    });
  },
  data() {
    return {
      changes: false,
      savedModel: {},
      saving: false,
      loading: false,
      model: {},
      guidHelper: new GuidHelper(),
      storeManager: new StoreManager(),
      dataSource: null,
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: true,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
      columns: [
        new TextColumnConfig({
          dataField: "columnTitle",
          caption: "Заголовок колонки",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "fieldDescription",
          caption: "Описание",
          readOnly: true,
        }),
        new BooleanColumnConfig({
          dataField: "isRequired",
          caption: "Обязательна",
          readOnly: true,
          disabled: true,
        }),
        new IntegerColumnConfig({
          dataField: "exportOrder",
          caption: "Порядок",
          readOnly: true,
        }),
      ],
    };
  },
});
