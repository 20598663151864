export enum ColumnTemplateType {
  Boolean = "booleanTemplate",
  Catalogue = "catalogueTemplate",
  DateTime = "dateTimeTemplate",
  Decimal = "decimalTemplate",
  Enum = "enumTemplate",
  Integer = "integerTemplate",
  Text = "textTemplate",
  TextBooleanDateTime = "textBooleanDateTimeTemplate",
}
