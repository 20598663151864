import api from "@/services/api";
import { defineComponent } from "vue";

interface PollingMixinStateA {
  pollInterval: any;
  interval: any;
}

export default defineComponent({
  data(): PollingMixinStateA {
    return {
      pollInterval: null,
      interval: null,
    };
  },
  methods: {
    async poll(url: string, callback: any) {
      try {
        const { data } = await api.get(url);
        if (callback(data)) {
          this.stopPolling();
        }
      } catch (e: any) {
        console.error(
          `Обновление прервано. Проблема при соединении с сервером. Будет произведена повторная попытка через ${this.interval} мс.`
        );
        console.error("Детали проблемы:", e);
      }
    },
    startPolling(url: string, callback: any, interval: number) {
      this.interval = interval;
      this.poll(url, callback);
      this.pollInterval = setInterval(async () => {
        await this.poll(url, callback);
      }, interval);
    },
    stopPolling() {
      clearInterval(this.pollInterval);
    },
  },
  beforeUnmount() {
    this.stopPolling();
  },
});
