
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DxFileUploader,
  },
  emits: ["update:value", "changed"],
  props: {
    accept: { required: false },
    allowedFileExtensions: { required: false },
    baseValidationError: { required: false },
    value: { required: true },
  },
  methods: {
    valueChanged(e: any) {
      this.$emit("update:value", e.value);
      this.$emit("changed", e);
    },
    validate() {
      this.isValid = this.files.length > 0;
      return this.isValid;
    },

    fileUploader() {
      return this.$refs.fileUploader as any;
    },
    clear() {
      this.fileUploader().instance.reset();
    },
  },
  data() {
    return {
      files: [],
      isValid: true,
      validationError: this.baseValidationError,
    };
  },
});
