
import { defineComponent } from "vue";
import { DxCheckBox } from "devextreme-vue/check-box";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import { EnumColumnConfig } from "@/components/controls/catalogueGrid/columns/enumColumnConfig";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import planCargoSettingsItemCreateEditDialog from "./PlanCargoSettingsItemCreateEditDialog.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DxButton from "devextreme-vue/button";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";

export default defineComponent({
  components: {
    DxCheckBox,
    CatalogueGrid,
    FontAwesomeIcon,
    DxButton,
    IntegerControl,
    TextControl,
    CatalogueSelectControl,
  },
  props: {},
  computed: {
    planCargoSettingsItemCreateEditDialog() {
      return planCargoSettingsItemCreateEditDialog;
    },
  },
  methods: {
    onSave() {
      this.saving = true;
      this.storeManager.setEditModel(this.renderKey, { ...this.model });
      this.dataSource.update(this.model.id, { ...this.model }).then(() => {
        this.saving = false;
        this.savedModel = { ...this.model };
        this.haveChanges();
      });
    },
    haveChanges() {
      if (!this.loading)
        this.changes = !(
          JSON.stringify(this.savedModel) == JSON.stringify(this.model)
        );
    },
  },
  created() {
    this.renderKey = this.guidHelper.getUniqueId(4);
    this.dataSource = this.storeManager.getStandartGridCrudStore(
      "PlanCargoImportSettings",
      this.renderKey
    );
  },
  mounted() {
    this.loading = true;
    this.dataSource.getEdit(this.$route.params.id).then((model: any) => {
      this.model = model;
      this.loading = false;
      this.savedModel = { ...this.model };
      this.haveChanges();
    });
  },
  data() {
    return {
      changes: false,
      savedModel: {},
      loading: false,
      saving: false,
      model: {},
      selectMode: false,
      guidHelper: new GuidHelper(),
      storeManager: new StoreManager(),
      dataSource: null,
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        allowDeleteAll: true,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: true,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
      columns: [
        new TextColumnConfig({
          dataField: "columnTitle",
          caption: "Заголовок колонки",
          validationRules: [requiredRule],
        }),

        new EnumColumnConfig({
          dataField: "columnTypeDescription",
          caption: "Тип",
          enumType: "importColumnType",
          modelField: "columnType",
          modelDescField: "columnTypeDescription",
          descriptionColumnName: "columnTypeDescription",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "fieldFeatureDescription",
          caption: "Описание",
          readOnly: true,
        }),
        new BooleanColumnConfig({
          dataField: "isRequired",
          caption: "Обязательна",
          readOnly: true,
          disabled: true,
        }),
        new IntegerColumnConfig({
          dataField: "exportOrder",
          caption: "Порядок",
          readOnly: true,
        }),
      ],
    };
  },
});
