import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressDialog = _resolveComponent("ProgressDialog")!

  return (_openBlock(), _createBlock(_component_ProgressDialog, {
    title: "Копирование...",
    controller: _ctx.controller,
    progressMethodName: _ctx.getControllerMessageName() + 'CopyProgress',
    progressEventName: _ctx.getControllerMessageName() + 'CopyProgressEvent',
    messageEventName: _ctx.getControllerMessageName() + 'CopyMessageEvent',
    stopMethod: "StopCopy",
    ref: "progressDialog",
    onProgressClose: _ctx.onCopyProgressClose
  }, null, 8, ["controller", "progressMethodName", "progressEventName", "messageEventName", "onProgressClose"]))
}