
import { defineComponent } from "vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import progressJobMixin from "@/mixins/ProgressJobMixin";
import { ProgressStatus } from "@/components/workflows/enums";
import api from "@/services/api";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxProgressBar,
  },
  mixins: [progressJobMixin],
  emits: ["progressClose"],
  computed: {
    closeButtonVisible() {
      return this.started && this.status != ProgressStatus.InProgress;
    },
  },
  props: {
    title: { required: false, default: "Выполнение задачи..." },
    controller: { required: true },
    progressMethodName: { required: true },
    progressEventName: { required: true },
    messageEventName: { required: true },
    stopMethod: { required: true },
  },

  methods: {
    onShown() {
      this.status = ProgressStatus.InProgress;
      this.message = { Message: "Задача выполняется" };
    },
    onHidden() {
      this.started = false;
      this.status = undefined;
      this.message.Message = "";
      this.progressValue = 0;
      this.stopConnection();
    },
    progressStatusFormat(value) {
      return `Выполнено: ${Math.floor(value * 100)}%`;
    },
    async show(
      visible: boolean,
      callbacks: {
        startCallbackAsync: any;
      },
      idParent: any
    ) {
      this.progressValue = 0;
      this.popupVisible = visible;

      if (visible) {
        const { data } = await api.get(`${this.controller}/GetNewJobId`);
        this.jobId = data;
        const eventHandlers = [
          {
            eventName: this.progressEventName,
            handler: async (d: any) => {
              if (d.Status == ProgressStatus.Completed) {
                this.show(false);
              }
              (this.status = d.Status),
                (this.progressValue = Math.floor(
                  (d.Iteration / d.TotalIterations) * 100
                ));
            },
          },
        ];

        if (this.messageEventName) {
          eventHandlers.push({
            eventName: this.messageEventName,
            handler: async (d: any) => {
              this.message = d;
            },
          });
        }

        this.connectJob(
          this.jobId,
          this.progressMethodName,
          eventHandlers,
          async () => {
            if (callbacks.startCallbackAsync) {
              this.started = true;
              await callbacks.startCallbackAsync(
                this.jobId,
                idParent,
                this.controller
              );
            }
          }
        );
      } else {
        this.$emit("progressClose");
      }
    },
    async onStop() {
      //отключаемся от вебсокета!!!
      this.stopConnection();

      this.message = { Message: "Выполнение задачи остановлено." };

      try {
        this.stopping = true;
        await api.post(`${this.controller}/${this.stopMethod}`, this.jobId);
      } catch {
        this.stopping = false;
      } finally {
        this.stopping = false;
        this.status = ProgressStatus.Stopped; //Не дожидаемся ответа от сервера по веб-сокету
        this.progressValue = 100;
      }
    },
    onClose() {
      this.show(false);
    },
  },
  data() {
    return {
      started: false,
      stopping: false,
      jobId: "",
      status: undefined,
      progressValue: 0,
      message: { Message: "" },
      closeButtonOptions: {
        text: "Закрыть",
        onClick: this.onClose,
      },
      stopButtonOptions: {
        text: "Остановить",
        onClick: this.onStop,
      },
      popupVisible: false,
      fileName: undefined,
    };
  },
});
