/* eslint-disable */
import api from "@/services/api";
const gridStoreHelper = require("@/helpers/gridStoreHelper/gridStoreHelper")

export class StoreManager {
  public setEditModel(gridRenderKey: string, editModel: any) {
    gridStoreHelper.setEditModel(gridRenderKey, editModel);
  }

  public setCreateModel(gridRenderKey: string, createModel: any) {
    gridStoreHelper.setCreateModel(gridRenderKey, createModel);
  }

  public setParentModel(gridRenderKey: string, parentModel: any, customParentId: any) {
    if (customParentId)
      parentModel = {id: customParentId}
    gridStoreHelper.setParentModel(gridRenderKey, parentModel);
  }

  public setAdditionalFilters(gridRenderKey: string, additionalFilters: any) {
    gridStoreHelper.setAdditionalFilters(gridRenderKey, additionalFilters);
  }

  public getStandartGridCrudStore(controller: string, gridRenderKey: string, customLoadUrl: string, customParams: string) {
    return gridStoreHelper.createStore(
      {
        key: "id",
        loadUrl: `/${controller}/${customLoadUrl}`,
        saveUrl: `/${controller}/Save`,
        getCreateUrl: `/${controller}/GetCreateModel`,
        getEditUrl: `/${controller}/GetEditModel`,
        saveCopyUrl: `/${controller}/SaveCopyModel`,
        updateUrl: `/${controller}/Update`,
        deleteUrl: `/${controller}/Delete`,
        deleteAllUrl: `/${controller}/DeleteAll`,
        onBeforeSend: (method: any, ajaxOptions: any) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
        customParams: customParams,
      },
      gridRenderKey,
      api
    );
  }
}
