
import { DxCheckBox } from "devextreme-vue/check-box";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DxCheckBox,
  },
  emits: ["update:value", "changed"],
  props: {
    //значение
    value: { type: Boolean, required: true },
    //показатель видимости контрола
    visible: { type: Boolean, required: false, default: true },
    //показатель валидации
    isValid: { type: Boolean, required: false, default: true },
    //показатель возможности изменения значения
    readOnly: { type: Boolean, required: false, default: false },
    //показатель возможности взаимодействия
    disabled: { type: Boolean, required: false, default: false },
    //подсказка при наведении на контрол
    hint: { type: String, required: false, default: undefined },
    contentReady: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  methods: {
    valueChanged(e: any) {
      this.$emit("update:value", e.value);
      this.$emit("changed", e);
    },
  },
  data() {
    return {
      modelValue: null,
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.modelValue = value;
      },
    },
  },
  created() {
    (this as any).modelValue = this.value;
  },
});
