import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImportAllSelectDialog = _resolveComponent("ImportAllSelectDialog")!
  const _component_ImportExportProgressDialog = _resolveComponent("ImportExportProgressDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ImportAllSelectDialog, {
      controller: _ctx.controller,
      importConfig: _ctx.importConfig,
      planId: _ctx.planId,
      onPassCallbacks: _ctx.onPassCallbacks,
      ref: "importAllSelectDialog"
    }, null, 8, ["controller", "importConfig", "planId", "onPassCallbacks"]),
    _createVNode(_component_ImportExportProgressDialog, {
      controller: _ctx.controller,
      importExportConfig: _ctx.importConfig,
      onImportExportClose: _ctx.onImportExportClose,
      ref: "importProgressDialog",
      mode: _ctx.mode
    }, null, 8, ["controller", "importExportConfig", "onImportExportClose", "mode"])
  ]))
}