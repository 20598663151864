import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "../store/authModule";
import { settings } from "../store/settingsModule";
import FormLogin from "@/views/FormLogin.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Plan from "@/views/journals/Plan.vue";
import PlanTransportImportSettings from "@/views/administration/Import/PlanTransportImportSettings/PlanTransportImportSettings.vue";
import PlanCargoImportSettings from "@/views/administration/Import/PlanCargoImportSettings/PlanCargoImportSettings.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/:catchAll(.*)", name: "PageNotFound", component: PageNotFound },
  {
    path: "/",
    name: "Home",
    meta: { layout: "main", auth: true, title: "Главная" },
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "auth", auth: false, title: "Вход" },
    component: FormLogin,
  },

  {
    path: "/profile",
    name: "profile",
    meta: { layout: "main", auth: true, title: "Профиль" },
    component: () => import("../views/Profile.vue"),
  },

  //Catalogues
  {
    path: "/gap",
    name: "gap",
    meta: { layout: "main", auth: true, title: "Отступы" },
    component: () => import("../views/catalogues/Gap.vue"),
  },
  {
    path: "/displacement",
    name: "displacement",
    meta: {
      layout: "main",
      auth: true,
      title: "Допускаемое смещение общего ЦТ груза в транспорте",
    },
    component: () => import("../views/catalogues/Displacement.vue"),
  },
  {
    path: "/transportContainer",
    name: "transportContainer",
    meta: { layout: "main", auth: true, title: "Контейнеры" },
    component: () => import("../views/catalogues/TransportContainer.vue"),
  },
  {
    path: "/transportBoxCar",
    name: "transportBoxCar",
    meta: { layout: "main", auth: true, title: "Грузовые вагоны" },
    component: () => import("../views/catalogues/TransportBoxCar.vue"),
  },
  {
    path: "/transportMotor",
    name: "transportMotor",
    meta: { layout: "main", auth: true, title: "Автотранспорт" },
    component: () => import("../views/catalogues/TransportMotor.vue"),
  },
  {
    path: "/cargoPallet",
    name: "cargoPallet",
    meta: { layout: "main", auth: true, title: "Паллеты" },
    component: () => import("../views/catalogues/CargoPallet.vue"),
  },
  {
    path: "/cargoRoll",
    name: "cargoRoll",
    meta: { layout: "main", auth: true, title: "Рулоны" },
    component: () => import("../views/catalogues/CargoRoll.vue"),
  },
  {
    path: "/dCatalogue",
    name: "dCatalogue",
    meta: { layout: "main", auth: true, title: "Динамические справочники" },
    component: () => import("../views/catalogues/DCatalogue.vue"),
  },
  {
    path: "/feature",
    name: "feature",
    meta: { layout: "main", auth: true, title: "Свойства" },
    component: () => import("../views/catalogues/Feature.vue"),
  },
  {
    path: "/requisite",
    name: "requisite",
    meta: {
      layout: "main",
      auth: true,
      title: "Реквизиты",
    },
    component: () => import("../views/catalogues/Requisite.vue"),
  },

  //Journals
  {
    path: "/plans",
    name: "plans",
    meta: { layout: "main", auth: true, title: "Планы" },
    component: () => import("../views/journals/Plans.vue"),
  },

  {
    path: "/plans/:id",
    name: "plan",
    meta: { layout: "main", auth: true, title: "План" },
    component: Plan,
  },

  //Administration
  {
    path: "/users",
    name: "users",
    meta: { layout: "main", auth: true, title: "Пользователи" },
    component: () => import("../views/administration/Users.vue"),
  },

  {
    path: "/company",
    name: "company",
    meta: { layout: "main", auth: true, title: "Компании" },
    component: () => import("../views/administration/Company.vue"),
  },

  {
    path: "/roles",
    name: "roles",
    meta: { layout: "main", auth: true, title: "Роли" },
    component: () => import("../views/administration/Roles.vue"),
  },

  {
    path: "/import",
    name: "import",
    meta: { layout: "main", auth: true, title: "Импорт данных" },
    component: () => import("../views/administration/Import/Import.vue"),
  },
  {
    path: "/planManager",
    name: "planManager",
    meta: {
      layout: "main",
      auth: true,
      title: "Менеджер по управлению планами",
    },
    component: () => import("../views/administration/PlanManager.vue"),
  },
  {
    path: "/logging",
    name: "logging",
    meta: { layout: "main", auth: true, title: "Действия пользователей" },
    component: () => import("../views/administration/Logging.vue"),
  },
  {
    path: "/cleanup",
    name: "cleanup",
    meta: { layout: "main", auth: true, title: "Очистка данных" },
    component: () => import("../views/administration/Cleanup.vue"),
  },

  //Reports
  {
    path: "/loadingReport",
    name: "loadingReport",
    meta: { layout: "main", auth: true, title: "Отчет о погрузке" },
    component: () => import("../views/reports/LoadingReport.vue"),
  },

  {
    path: "/loadingRZDReport",
    name: "loadingRZDReport",
    meta: { layout: "main", auth: true, title: "Отчет о погрузке РЖД" },
    component: () => import("../views/reports/LoadingRZDReport.vue"),
  },

  //Settings
  {
    path: "/settings",
    name: "settings",
    meta: { layout: "main", auth: true, title: "Настройки" },
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/planCargoImportSettings/:id",
    name: "planCargoImportSettings",
    meta: { layout: "main", auth: true, title: "Настройки импорта грузов" },
    component: PlanCargoImportSettings,
  },
  {
    path: "/planTransportImportSettings/:id",
    name: "planTransportImportSettings",
    meta: {
      layout: "main",
      auth: true,
      title: "Настройки импорта транспорта",
    },
    component: PlanTransportImportSettings,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.title) document.title = "Opti Loading";
  else document.title = to.meta.title as string;
  if (to.meta?.auth && to.name !== "login" && !auth.state.user)
    next({ name: "login" });
  else {
    next();
  }
});

export default router;
