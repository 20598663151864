
import { defineComponent } from "vue";
import DxButton from "devextreme-vue/button";

export default defineComponent({
  components: { DxButton },
  methods: {
    closeScheme() {
      this.$emit("closeScheme");
    },
  },
});
