import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportSelectDialog = _resolveComponent("ExportSelectDialog")!
  const _component_ImportExportProgressDialog = _resolveComponent("ImportExportProgressDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ExportSelectDialog, {
      controller: _ctx.controller,
      exportConfig: _ctx.exportConfig,
      onPassCallbacks: _ctx.onPassCallbacks,
      ref: "exportSelectDialog"
    }, null, 8, ["controller", "exportConfig", "onPassCallbacks"]),
    _createVNode(_component_ImportExportProgressDialog, {
      controller: _ctx.controller,
      importExportConfig: _ctx.exportConfig,
      ref: "exportProgressDialog",
      mode: _ctx.mode
    }, null, 8, ["controller", "importExportConfig", "mode"])
  ]))
}