/* eslint-disable */
import {NumberColumnConfig, INumberColumnConfig} from './numberColumnConfig';
import { ColumnTemplateType } from './enums';

export class IntegerColumnConfig extends NumberColumnConfig {
  constructor(config: INumberColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Integer;
    super(config);
  }
}
