import { defineComponent } from "vue";
import * as signalR from "@microsoft/signalr";
import { HubConnectionBuilder } from "@microsoft/signalr";

interface ProgressJobMixinState {
  connection: HubConnectionBuilder | null;
}

export default defineComponent({
  data(): ProgressJobMixinState {
    return {
      connection: null,
    };
  },
  methods: {
    getUrl(jobName: any, serverUrl: any) {
      if (serverUrl) {
        return `${serverUrl}/${jobName}`;
      }
      return `${location.protocol}//${location.host}/${jobName}`;
    },
    connectJob(
      jobId: string,
      jobName: string,
      eventHandlers: { eventName: string; handler: any }[],
      runCallback = null,
      serverUrl = null
    ) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.getUrl(jobName, serverUrl), {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .build();

      eventHandlers.forEach((e) => {
        this.connection.on(e.eventName, (data: any) => {
          e.handler(JSON.parse(data));
        });
      });

      this.connection.start().then(async () => {
        await this.connection.invoke("AssociateJob", jobId);
        if (runCallback) await runCallback();
      });
    },
    stopConnection() {
      if (this.connection) {
        this.connection.stop();
      }
    },
  },
  beforeUnmount() {
    this.stopConnection();
  },
});
