/* eslint-disable */
import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class TextColumnConfig extends ColumnConfig {
  mode?: string;
  maxLength?: number;
  constructor(config: ITextColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Text;
    if (!config.defaultValue) config.defaultValue = null as String | null;
    super(config);
    this.mode = config.mode;
    this.maxLength = config.maxLength;
  }
}

export interface ITextColumnConfig extends IColumnConfig {
  mode?: string;
  maxLength?: number;
}
