export const getTabs = (can: any) => [
  {
    id: "1",
    name: "Главная",
    url: "/",
  },
  {
    id: "2",
    name: "Справочники",
    visible: can("view", "Menu.Catalogues"),
    items: [
      {
        id: "2_1",
        name: "Рулоны",
        visible: can("view", "Menu.Catalogues.CargoRoll"),
        url: "/cargoRoll",
      },
      {
        id: "2_2",
        name: "Паллеты",
        visible: can("view", "Menu.Catalogues.CargoPallet"),
        url: "/cargoPallet",
      },
      {
        id: "2_3",
        name: "Грузовые вагоны",
        visible: can("view", "Menu.Catalogues.TransportBoxCar"),
        url: "/transportBoxCar",
      },
      {
        id: "2_4",
        name: "Автотранспорт",
        visible: can("view", "Menu.Catalogues.TransportMotor"),
        url: "/transportMotor",
      },
      {
        id: "2_5",
        name: "Контейнеры",
        visible: can("view", "Menu.Catalogues.TransportContainer"),
        url: "/transportContainer",
      },
      {
        id: "2_6",
        name: "Смещения",
        visible: can("view", "Menu.Catalogues.Displacement"),
        url: "/displacement",
      },
      {
        id: "2_7",
        name: "Отступы",
        visible: can("view", "Menu.Catalogues.Gap"),
        url: "/gap",
      },
      {
        id: "2_8",
        name: "Реквизиты",
        visible: can("view", "Menu.Catalogues.Requisite"),
        url: "/requisite",
      },
    ],
  },
  {
    id: "3",
    name: "Журналы",
    visible: can("view", "Menu.Journals"),
    items: [
      {
        id: "3_1",
        name: "Планы",
        visible: can("view", "Menu.Journals.Plan"),
        url: "/plans",
      },
    ],
  },
  {
    id: "4",
    name: "Отчеты",
    visible: can("view", "Menu.Reports"),
    items: [
      {
        id: "4_1",
        name: "Отчет о погрузке",
        visible: can("view", "Menu.Reports.Loading"),
        url: "/loadingReport",
      },
      {
        id: "4_2",
        name: "Отчет о погрузке РЖД",
        visible: can("view", "Menu.Reports.LoadingRzd"),
        url: "/loadingRZDReport",
      },
    ],
  },
  {
    id: "5",
    name: "Администрирование",
    visible: can("view", "Menu.System"),
    items: [
      {
        id: "5_1",
        name: "Пользователи",
        visible: can("view", "Menu.System.User"),
        url: "/users",
      },
      {
        id: "5_2",
        name: "Компании",
        visible: can("view", "Menu.System.Company"),
        url: "/company",
      },
      {
        id: "5_3",
        name: "Роли",
        visible: can("view", "Menu.System.Role"),
        url: "/roles",
      },
      {
        id: "5_4",
        name: "Импорт данных",
        visible: can("view", "Menu.System.Import"),
        url: "/import",
      },
      {
        id: "5_5",
        name: "Менеджер по управлению планами",
        visible: can("view", "Menu.System.ManagerPlan"),
        url: "/planManager",
      },
      {
        id: "5_6",
        name: "Действия пользователей",
        visible: can("view", "Menu.System.Logging"),
        url: "/logging",
      },
      {
        id: "5_7",
        name: "Очистка данных",
        visible: can("view", "Menu.System.Cleanup"),
        url: "/cleanup",
      },
    ],
  },
  {
    id: "6",
    name: "Настройки",
    visible: can("view", "Menu.Settings"),
    url: "/settings",
  },
];

export const showFirstSubmenuModes = {
  name: "onHover",
  delay: { show: 0, hide: 100 },
};
