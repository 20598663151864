
import { DxButton } from "devextreme-vue/button";
import { DxTextBox } from "devextreme-vue/text-box";
import {
  DxValidator,
  DxStringLengthRule,
  DxCustomRule,
} from "devextreme-vue/validator";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DxButton,
    DxValidator,
    DxStringLengthRule,
    DxCustomRule,
    DxTextBox,
  },
  emits: ["update:value"],
  watch: {
    passwordText: function () {
      this.submitPasswordInstance.validate();
    },
  },
  methods: {
    onInitialized: function (e) {
      (this as any).submitPasswordInstance = e.component;
    },

    validatePasswords() {
      return this.passwordText == this.submitPasswordText;
    },
    onClick() {
      (this as any).isShow = true;
    },
    onCloseClick() {
      (this as any).passwordText = "";
      (this as any).submitPasswordText = "";
      (this as any).isShow = false;
      //setTimeout(() => (this as any).isShow = false, 50);
    },

    onFormSubmit(e: any) {
      e.preventDefault();
      (this as any).isShow = false;
      this.$emit("update:value", this.submitPasswordText);
    },
  },
  data() {
    return {
      isShow: false,
      submitPasswordInstance: null as any,
      passwordText: "",
      submitPasswordText: "",
    };
  },
});
