
import DxTextArea from "devextreme-vue/text-area";
import { defineComponent } from "vue";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    DxTextArea,
    DxValidator,
    DxRequiredRule,
  },
  props: {
    mode: { type: String, required: false },
    //максимальная длина текста
    maxLength: { type: Number, required: false, default: undefined },
    //показатель видимости контрола
    visible: { type: Boolean, required: false, default: true },
    //показатель валидации
    isValid: { type: Boolean, required: false, default: true },
    //placeholder
    placeholder: { type: String, required: false, default: "" },
    //показатель возможности изменения значения
    readOnly: { type: Boolean, required: false, default: false },
    //показатель возможности взаимодействия
    disabled: { type: Boolean, required: false, default: false },
    //подсказка при наведении на контрол
    hint: { type: String, required: false, default: undefined },
    //значение
    value: { type: String, required: false },
    //высота
    height: { type: Number, required: false },
    //ширина
    width: { type: Number, required: false },
    //максимальная высота
    maxHeight: { type: Number, required: false },
    //минимальная высота
    minHeight: { type: Number, required: false },
    //автоматическое изменение размера по размеру текста
    autoResizeEnabled: { type: Boolean, required: false, default: true },
    //отображать кнопку очистки значения
    hasEmptyValue: { type: Boolean, required: false, default: false },
    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },

    keyDown: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
    enterKey: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  methods: {
    valueChanged(e: any) {
      this.$emit("value-changed-evt", e);
    },

    contentReady(e: any) {
      this.$emit("content-ready-evt", e);
    },

    initialized(e: any) {
      this.$emit("initialized-evt", e);
    },

    self() {
      return this as any;
    },
  },
});
