export class TableEditingConfig {
  public allowUpdating = true;
  public allowAdding = true;
  public allowDeleting = true;
  public confirmDelete = true;
  public allowDataFill = false;
  public allowDeleteAll = false;
  public allowExport = false;
  public allowImport = false;
  public allowCopy = false;
  public allowClearFilter = false;
  public allowDeleteMultipleRows = false;
  public allowGrouping = false;
  public allowColumnReordering = false;
  public allowResetSelectedRows = false;

  public pageEditing = false;
  public pageName = "";

  public enableMasterDetail = false;
  public detailComponentName = undefined;

  public rowDblClickEditing = true;

  public mode = "popup"; //popup|row|cell

  public chooseColumns = false;

  public showInfo = false;

  public deleteAllTitle = "Удалить все";

  public allowExportToExcel = false;

  public exportedFileName = "Opti-loading";

  constructor(config: any) {
    Object.assign(this, config);
  }
}
