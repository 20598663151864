export class TableExportConfig {
  public startMethod = "StartExport";
  public stopMethod = "StopExport";
  public progressMethodName = "";
  public progressEventName = "";
  public messageEventName: string | null = null;
  public exportFileName = "Файл экспорта-Opti-Loading";

  constructor(config: any) {
    Object.assign(this, config);
  }
}
