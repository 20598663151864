
import { DxMenu } from "devextreme-vue";
import { getTabs, showFirstSubmenuModes } from "./mainMenuConfig";

import { defineComponent } from "vue";
import { auth } from "@/store/authModule";
import { DxPopover } from "devextreme-vue/popover";
import { DxButton } from "devextreme-vue/button";

export default defineComponent({
  components: {
    DxMenu,
    DxPopover,
    DxButton,
  },
  props: {},
  methods: {
    click(e: any) {
      if (e.itemData.url && e.itemData.url !== this.$route.path) {
        this.$router.push(e.itemData.url);
      }
    },

    logOut() {
      (this as any).$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    profile() {
      this.$router.push("/profile");
    },
    toogleUserPopover() {
      this.userPopoverVisible = !this.userPopoverVisible;
    },
    onUserPopoverHidden() {
      this.userPopoverVisible = false;
    },
  },
  data() {
    return {
      userPopoverVisible: false,
      orientation: "horizontal",
      submenuDirection: "auto",
      hideSubmenuOnMouseLeave: true,
      showFirstSubmenuModes: showFirstSubmenuModes,
      adaptivityEnabled: true,
      tabs: getTabs(this.$can),
    };
  },
  computed: {
    userName: function () {
      return auth.state.user?.username;
    },
    userFullName: function () {
      return auth.state.user?.userfullname;
    },
    productVersion: function () {
      return `${process.env.VUE_APP_VERSION}`;
    },
  },
});
