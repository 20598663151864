import { createApp, defineAsyncComponent, defineComponent, h } from "vue";

interface MountComponentMixinState {
  componentInstances: any;
}

export default defineComponent({
  data(): MountComponentMixinState {
    return {
      componentInstances: {},
    };
  },
  methods: {
    mountComponent(componentPath: string, mountDomId: string, props: any) {
      const ac: any = defineAsyncComponent(() => import(`@/${componentPath}`));

      (this as any).componentInstances[mountDomId] = createApp({
        render: () => h(ac, props),
      });
      (this as any).componentInstances[mountDomId].mount(`#${mountDomId}`);
    },

    unmountComponent(mountDomId: string) {
      if (this.componentInstances[mountDomId])
        (this.componentInstances[mountDomId] as any).unmount();
    },
  },
});
