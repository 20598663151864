const settingsStored = JSON.parse(
  localStorage.getItem("settingsStored") as string
);
const initialState = settingsStored
  ? { settings: settingsStored }
  : { settings: {} };

export const settings = {
  namespaced: true,
  state: initialState,
  actions: {
    setSettings({ commit }: any, current: { settings: any }) {
      commit("setSettings", current);
      localStorage.setItem(
        "settingsStored",
        JSON.stringify(this.state.settings.settings)
      );
    },
    clearSettings({ commit }: any) {
      commit("clearSettings");
      localStorage.setItem("settingsStored", JSON.stringify({}));
    },
  },
  mutations: {
    setSettings(state: any, current: any) {
      state.settings = current.settings;
    },
    clearSettings(state: any) {
      state.settings = {};
    },
  },
};
