
import { defineComponent } from "vue";
import {
  DxPopup as DxEditPopup,
  DxPosition as DxEditPosition,
} from "devextreme-vue/popup";

import { DxScrollView } from "devextreme-vue/scroll-view";

export default defineComponent({
  components: {
    DxEditPopup,
    DxEditPosition,
    DxScrollView,
  },
  props: {
    title: { required: false, default: "Справка" },
    maxWidth: { default: "900px" },
    minWidth: { default: null },
    height: { default: null },
  },
  methods: {
    open() {
      this.visible = true;
    },
  },
  data() {
    return {
      visible: false,
    };
  },
});
