
import { defineComponent } from "vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import progressJobMixin from "@/mixins/ProgressJobMixin";
import { ProgressStatus } from "../enums";
import api from "@/services/api";
import { MessageStatus } from "../enums";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import { ImportExportType } from "./ImportExportType";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxProgressBar,
    DxLoadPanel,
    DxCheckBox,
  },
  mixins: [progressJobMixin],
  emits: ["importExportClose"],
  computed: {
    closeButtonVisible() {
      return this.started && this.status != ProgressStatus.InProgress;
    },
    hasErrorsOrWarnings() {
      return this.messages.some((item: any) =>
        this.isMessageErrorOrWarning(item)
      );
    },
    isImport() {
      return this.mode == ImportExportType.Import;
    },
  },
  watch: {
    isMessage: function () {
      this.updateScrolling();
    },
    isWarning: function () {
      this.updateScrolling();
    },
    isError: function () {
      this.updateScrolling();
    },
  },
  props: {
    controller: { required: true },
    importExportConfig: { required: true },
    mode: { required: true },
  },

  created() {
    window.addEventListener("beforeunload", this.confirmLeaving);
  },

  methods: {
    confirmLeaving(evt: any) {
      if (this.status == ProgressStatus.InProgress) {
        this.onStop();
        let unsavedChangesWarning = `${
          this.isImport ? "Импорт" : "Экспорт"
        } данных будет остановлен. Вы уверены, что хотите выйти?`;
        evt.returnValue = unsavedChangesWarning;
        return unsavedChangesWarning;
      }
    },
    getMessageColor(messageType: any) {
      return messageType == MessageStatus.Error
        ? "#E20338"
        : messageType == MessageStatus.Warning
        ? "#FF7A2F"
        : "";
    },
    getVisibility(messageType: any) {
      return (
        (messageType == MessageStatus.Message && this.isMessage) ||
        (messageType == MessageStatus.Error && this.isError) ||
        (messageType == MessageStatus.Warning && this.isWarning)
      );
    },
    updateScrolling() {
      const scrollView = this.$refs.scrollView;
      if (scrollView && this.isScrollUpdating) {
        const instance = scrollView.instance;
        instance.scrollTo(instance.scrollHeight());
      }
    },
    onShown() {
      this.status = ProgressStatus.InProgress;
    },
    onHidden() {
      this.resetValues();
      this.started = false;
      this.status = undefined;

      this.stopConnection();
    },
    resetValues() {
      this.messages = [];
      this.viewMessages = [];
      this.progressValue = 0;
      this.downloadButtonVisible = false;
    },
    onShowDetailsClick(m: any) {
      m.detailsVisible = true;
    },
    onHideDetailsClick(m: any) {
      m.detailsVisible = false;
    },
    progressStatusFormat(value) {
      return `Выполнено: ${Math.floor(value * 100)}%`;
    },
    async show(
      visible: boolean,
      callbacks: {
        file: any;
        startCallbackAsync: any;
        completeCallbackAsync: any;
      },
      idParent: any
    ) {
      this.resetValues();
      this.popupVisible = visible;
      this.idParent = idParent;

      if (visible) {
        const { data } = await api.get(`${this.controller}/GetNewJobId`);
        this.jobId = data;

        const eventHandlers = [
          {
            eventName: this.importExportConfig.progressEventName,
            handler: async (d: any) => {
              if (d.Status == ProgressStatus.Completed) {
                if (callbacks.completeCallbackAsync) {
                  await callbacks.completeCallbackAsync(
                    callbacks.file,
                    this.jobId,
                    this.controller
                  );

                  //Закрываем только если нет ошибок
                  if (!this.hasErrorsOrWarnings) this.show(false);
                }
                this.updateDownloadButtonVisibility();
              }

              // Не дожидаемся, пока с веб-сокета придет подтверждение остановки
              //if (d.Status == ProgressStatus.Stopped)
              //  this.stopping = false;

              (this.status = d.Status),
                (this.progressValue = Math.floor(
                  (d.Iteration / d.TotalIterations) * 100
                ));
            },
          },
        ];

        if (this.importExportConfig.messageEventName) {
          eventHandlers.push({
            eventName: this.importExportConfig.messageEventName,
            handler: async (d: any) => {
              if (d.Models) d.Models.forEach((m: any) => this.addMessage(m));
            },
          });
        }

        this.connectJob(
          this.jobId,
          this.importExportConfig.progressMethodName,
          eventHandlers,
          async () => {
            if (callbacks.startCallbackAsync) {
              this.started = true;
              await callbacks.startCallbackAsync(
                callbacks.file,
                this.jobId,
                idParent,
                this.controller,
                this.importExportConfig
              );
            }
          }
        );
      }
    },
    addMessage(messageData: any) {
      this.updateScrolling();
      const message = { ...messageData, detailsVisible: false };
      this.messages.push(message);
      if (this.viewMessages.length > 1000) {
        this.viewMessages.shift();
      }
      this.viewMessages.push(message);
    },
    async onStop() {
      //отключаемся от вебсокета!!!
      this.stopConnection();

      this.addMessage({
        Message: "Операция остановлена пользователем.",
        Details: null,
        Type: 0,
      });

      try {
        const formData = new FormData();
        formData.append("jobId", this.jobId);
        const params = {};
        if (this.idParent) {
          params["IdParent"] = this.idParent;
          formData.append("planId", this.idParent);
        }
        this.stopping = true;
        await api.post(
          `${this.controller}/${this.importExportConfig.stopMethod}`,
          formData,
          {
            params,
          }
        );
      } catch {
        this.stopping = false;
      } finally {
        this.stopping = false;
        this.status = ProgressStatus.Stopped; //Не дожидаемся ответа от сервера по веб-сокету
        this.progressValue = 100;
        this.updateDownloadButtonVisibility();
      }
    },
    updateDownloadButtonVisibility() {
      this.downloadButtonVisible = this.hasErrorsOrWarnings;
    },
    isMessageErrorOrWarning(message: any) {
      return message.Type != MessageStatus.Message;
    },
    onClose() {
      this.$emit("importExportClose");
      this.show(false);
    },
    onDownload() {
      let errors = this.messages.filter((item: any) =>
        this.isMessageErrorOrWarning(item)
      );
      let data = JSON.stringify(errors, null, " ");
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/json" });
      element.href = URL.createObjectURL(file);
      element.download = "errors.json";
      element.click();
      element.remove();
    },
  },
  data() {
    return {
      idParent: null,
      downloadButtonVisible: false,
      isMessage: true,
      isWarning: true,
      isError: true,
      isScrollUpdating: true,
      started: false,
      stopping: false,
      jobId: "",
      status: undefined,
      scrollKey: 0,
      progressValue: 0,
      messages: [],
      viewMessages: [],
      importInfo: "",
      closeButtonOptions: {
        text: "Закрыть",
        onClick: this.onClose,
      },
      downloadButtonOptions: {
        text: "Выгрузить ошибки",
        onClick: this.onDownload,
        disabled: this.stopping,
      },
      stopButtonOptions: {
        text: "Остановить",
        onClick: this.onStop,
      },
      popupVisible: false,
      fileName: undefined,
      loadingPanelPosition: { of: "#scroll" },
    };
  },
});
