
import { DxNumberBox } from "devextreme-vue/number-box";
import { defineComponent } from "vue";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
  },
  emits: ["update:value", "changed"],
  props: {
    //количество цифр после запятой
    decimalPoints: { type: Number, required: false },
    //отображать нули для значащих разрядов после запятой при отсутствии значения
    showZeroTail: { type: Boolean, required: false, default: false },
    //показатель видимости контрола
    visible: { type: Boolean, required: false, default: true },
    //показатель валидации
    isValid: { type: Boolean, required: false, default: true },
    //минимальное значение
    min: { type: Number, required: false, default: undefined },
    //максимальное значение
    max: { type: Number, required: false, default: undefined },
    //placeholder
    placeholder: { type: String, required: false, default: "" },
    //показатель возможности изменения значения
    readOnly: { type: Boolean, required: false, default: false },
    //показатель возможности взаимодействия
    disabled: { type: Boolean, required: false, default: false },
    //отображать кнопку очистки значения
    hasEmptyValue: { type: Boolean, required: false, default: false },
    //подсказка при наведении на контрол
    hint: { type: String, required: false, default: undefined },
    //значение
    value: { type: Number, required: false },
    //интервал изменения значения
    step: { type: Number, required: false, default: 0.1 },
    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },
    contentReady: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
    enterKey: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  methods: {
    valueChanged(e: any) {
      this.$emit("update:value", e.value);
      this.$emit("changed", e);
    },
    setDecimalPoints() {
      if (!(this as any).decimalPoints) return;
      let format = "#,##0.";
      for (let i = 0; i < (this as any).decimalPoints; ++i) {
        if ((this as any).showZeroTail) format += "0";
        else format += "#";
      }
      (this as any).formatStr = format;
    },
  },
  data() {
    return {
      modelValue: null,
      formatStr: "",
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.modelValue = value;
      },
    },
  },
  created() {
    (this as any).modelValue = this.value;
    this.setDecimalPoints();
  },
});
