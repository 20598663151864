const planSettingsStored = JSON.parse(
  localStorage.getItem("planSettingsStored") as string
);
const initialState = planSettingsStored
  ? { planSettings: planSettingsStored }
  : { planSettings: {} };

export const planSettings = {
  namespaced: true,
  state: initialState,
  actions: {
    setPlanSettings({ commit }: any, current: { settings: any; index: any }) {
      commit("setPlanSettings", current);
      localStorage.setItem(
        "planSettingsStored",
        JSON.stringify(this.state.planSettings.planSettings)
      );
    },
  },
  mutations: {
    setPlanSettings(state: any, current: any) {
      state.planSettings[current.index] = current.settings;
    },
  },
};
