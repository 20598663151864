import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!

  return (_openBlock(), _createBlock(_component_DxTextBox, {
    value: _ctx.modelValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
    visible: _ctx.visible,
    isValid: _ctx.isValid,
    readOnly: _ctx.readOnly,
    disabled: _ctx.disabled,
    hint: _ctx.hint,
    placeholder: _ctx.placeholder,
    "max-length": _ctx.maxLength,
    "show-clear-button": _ctx.hasEmptyValue,
    stylingMode: _ctx.stylingMode,
    onValueChanged: _ctx.valueChanged,
    onContentReady: _ctx.contentReady,
    onOnEnterKey: _ctx.enterKey
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxValidator, { "validation-group": _ctx.validationGroup }, {
        default: _withCtx(() => [
          (!_ctx.hasEmptyValue)
            ? (_openBlock(), _createBlock(_component_DxRequiredRule, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["validation-group"])
    ]),
    _: 1
  }, 8, ["value", "visible", "isValid", "readOnly", "disabled", "hint", "placeholder", "max-length", "show-clear-button", "stylingMode", "onValueChanged", "onContentReady", "onOnEnterKey"]))
}