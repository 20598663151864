import { createStore } from "vuex";
import { auth } from "./authModule";
import { planSettings } from "./planSettingsModule";
import { settings } from "./settingsModule";
import { reportData } from "./reportDataModule";
import { calculationTime } from "./calculationTimeModule";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    planSettings,
    settings,
    reportData,
    calculationTime,
  },
});
