/* eslint-disable */
import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class EnumColumnConfig extends ColumnConfig {
  enumType!: string;
  modelField!: string;
  modelDescField! : string;

  constructor(config: IEnumColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Enum;
    super(config);
    this.enumType = config.enumType;
    this.modelField = config.modelField;
    this.modelDescField = config.modelDescField;
    this.displayField = this.modelDescField;
  }
}

export interface IEnumColumnConfig extends IColumnConfig {
  enumType: string;
  modelField: string;
  modelDescField: string;
}
