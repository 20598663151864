
import { defineComponent } from "vue";
import ProgressDialog from "@/components/workflows/progressDialog.vue";
import TokenService from "@/services/tokenService";
import axios from "axios";

export default defineComponent({
  components: {
    ProgressDialog,
  },
  emits: ["copyProgressClose"],
  methods: {
    getControllerMessageName() {
      var chars = this.controller.split("");
      chars[0] = this.controller[0].toLowerCase();
      return chars.join("");
    },

    getCopyProgressDialog() {
      return this.$refs.progressDialog as any;
    },

    start(id: any, parentId: any = 0) {
      this.getCopyProgressDialog().show(
        true,
        {
          startCallbackAsync: async (jobId, id) => {
            const formData = new FormData();
            formData.append("jobId", jobId);
            formData.append("parentId", parentId);

            const params = {};
            if (id) {
              params["id"] = id;
            }

            await axios
              .create({ baseURL: "/" })
              .post(`${this.controller}/StartCopy`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + TokenService.getLocalAccessToken(),
                },
                params,
              });
          },
        },
        id
      );
    },
    onCopyProgressClose() {
      this.$emit("copyProgressClose");
    },
  },
  data() {
    return {};
  },
  props: {
    controller: { required: true, type: String },
  },
});
