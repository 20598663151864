/* eslint-disable */
/*NOT USE*/
import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class BooleanColumnConfig extends ColumnConfig {
  constructor(config: IColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Boolean;
    if (!config.defaultValue) config.defaultValue = false;
    super(config);
  }
}
