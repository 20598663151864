
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import {
  ImportColumnType,
  FeatureType,
} from "@/components/catalogues/FeatureCatalogue/FeatureType";
import { DxCheckBox } from "devextreme-vue/check-box";
import TextControl from "@/components/controls/base/TextControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import api from "@/services/api";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
    CatalogueSelectControl,
/*    EnumControl,*/
    DxCheckBox,
    IntegerControl,
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  watch: {},
  computed: {
    isProperty() {
      return this.createEditModel.columnType == ImportColumnType.Property;
    },
    isField() {
      return this.createEditModel.columnType == ImportColumnType.Field;
    },
  },
  methods: {
    onIsBooleanTrueAnyNotEmptyValueChanged(value: any) {
      if (value) {
        this.createEditModel.booleanTrueValue = "";
      }
    },
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.createEditModel = this.dialog().createEditModel;
      this.controlType = null;
      this.fields = [];
      this.selectedField = null;

      api
        .get("/PlanCargoImportSettingsItem/GetFields")
        .then((res) => {
          let data: any = res.data;

          for (var key in data) {
            this.fields.push({ name: key, desc: data[key] })
          }

          this.selectedField = this.fields.filter(x => model.field == x.name)[0]?.name ?? null;
        });
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    fieldChanged(evt: any) {
      this.selectedField = evt.value;
      let desc = this.fields.filter(x => evt.value == x.name)[0]?.desc;
      if (this.createEditModel.field != evt.value) {
        this.createEditModel.field = this.selectedField;
        if (desc) {
          this.createEditModel.fieldDescription = desc;
          this.createEditModel.columnTitle = desc;
        }
      }
    },
    displayExpr(item) {
      return item ? item.name + ' (' + item.desc + ')' : "";
    }
  },
  data() {
    return {
      createEditModel: {},
      controlType: FeatureType.String,
      catalogueId: null,
      enumId: null,
      dCatalogueId: null,
      controlDescription: null,
      cataloguePath: null,
      enumType: null,
      dCatalogueModel: null,
      fields: [],
      selectedField: null,
    };
  },
});
