const calculationTimeStored = JSON.parse(
  localStorage.getItem("calculationTimeStored") as string
);
const initialState = calculationTimeStored
  ? { calculationTime: calculationTimeStored }
  : { calculationTime: {} };

export const calculationTime = {
  namespaced: true,
  state: initialState,
  actions: {
    setCalculationTime({ commit }: any, current: { data: any; index: any }) {
      commit("setCalculationTime", current);
      localStorage.setItem(
        "calculationTimeStored",
        JSON.stringify(this.state.calculationTime.calculationTime)
      );
    },
  },
  mutations: {
    setCalculationTime(state: any, current: any) {
      state.calculationTime[current.index] = current.data;
    },
  },
};
