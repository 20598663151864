class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user") as string);
    return user?.refreshToken;
  }

  getLocalAccessToken(): string {
    const user = JSON.parse(localStorage.getItem("user") as string);
    return user?.accessToken;
  }

  updateLocalAccessToken(token: string) {
    const user = JSON.parse(localStorage.getItem("user") as string);
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem("user") as string);
  }

  setUser(user: any): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser(): void {
    localStorage.removeItem("user");
  }
}

export default new TokenService();
