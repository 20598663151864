
import { defineComponent } from "vue";
import InfoScheme from "@/views/InfoScheme.vue";
import api from "@/services/api";
import DxButton from "devextreme-vue/button";

export default defineComponent({
  components: { InfoScheme, DxButton },
  props: {},
  methods: {
    openScheme() {
      this.showReadme = true;
      this.showImage = false;
    },
    closeScheme() {
      this.showReadme = false;
      this.showImage = true;
    },
  },
  async created() {
    await api.get("/Login/CheckAuth");
  },
  data() {
    return {
      showReadme: false,
      showImage: true,
    };
  },
});
