const reportDataStored = JSON.parse(
  localStorage.getItem("reportDataStored") as string
);
const initialState = reportDataStored
  ? { reportData: reportDataStored }
  : { reportData: {} };

export const reportData = {
  namespaced: true,
  state: initialState,
  actions: {
    setReportData({ commit }: any, current: { data: any }) {
      commit("setReportData", current);
      localStorage.setItem(
        "reportDataStored",
        JSON.stringify(this.state.reportData.reportData)
      );
    },
  },
  mutations: {
    setReportData(state: any, current: any) {
      state.reportData = current.data;
    },
  },
};
