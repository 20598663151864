import { updateAppAbility } from "@/abilities/ability";
import AuthService from "../services/authService";

const user = JSON.parse(localStorage.getItem("user") as string);
const route = localStorage.getItem("previousRoute") as string;
const initialState = user
  ? { status: { loggedIn: true }, user, routeAfterLogin: route ? route : "/" }
  : {
      status: { loggedIn: false },
      user: null,
      routeAfterLogin: route ? route : "/",
    };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }: any, user: any) {
      return AuthService.login(user).then(
        (user: any) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error: any) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }: any) {
      AuthService.logout();
      commit("logout");
    },
    refreshToken({ commit }: any, accessToken: any) {
      commit("refreshToken", accessToken);
    },
    rememberPath({ commit }: any, path: string) {
      localStorage.setItem("previousRoute", path);
      commit("rememberPath", path);
    },
  },
  getters: {
    getRememberedPath: (state: { routeAfterLogin: any }) => {
      const route = state.routeAfterLogin;
      if (route != "/") localStorage.removeItem("previousRoute");
      state.routeAfterLogin = "/";
      return route;
    },
    getPolicies: (state: any) => {
      if (state.user != null && state.user.policies)
        return state.user.policies.map((p: any) => p.policy);
      return [];
    },
    getModules: (state: any) => {
      if (state.user != null && state.user.modules) return state.user.modules;
      return null;
    },
  },
  mutations: {
    loginSuccess(state: any, user: any) {
      state.status.loggedIn = true;
      state.user = user;
      updateAppAbility();
    },
    loginFailure(state: any) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state: any) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state: any) {
      state.status.loggedIn = false;
    },
    registerFailure(state: any) {
      state.status.loggedIn = false;
    },
    refreshToken(state: any, accessToken: any) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    rememberPath(state: any, routeAfterLogin: any) {
      state.routeAfterLogin = routeAfterLogin;
    },
  },
};
