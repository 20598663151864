
import { defineComponent } from "vue";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import DateControl from "@/components/controls/base/DateControl.vue";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxButton from "devextreme-vue/button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DxTabPanel from "devextreme-vue/tab-panel";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import TextControl from "@/components/controls/base/TextControl.vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import pollingMixin from "@/mixins/PollingMixin";
import { MarginUnitType, PlanState } from "./enums";
import api from "@/services/api";
import { TableImportConfig } from "@/components/controls/catalogueGrid/tableImportConfig";
import ImportAllWorkflow from "@/components/workflows/importExport/importAllWorkflow/ImportAllWorkflow.vue";
import ExportAllWorkflow from "@/components/workflows/importExport/exportAllWorkflow/ExportAllWorkflow.vue";
import { TableExportConfig } from "@/components/controls/catalogueGrid/tableExportConfig";
import { settingsId } from "@/const/idConstant";
import PlanInnerInfoDialog from "@/components/info/PlanInnerInfoDialog.vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import SettingsService from "@/services/settingsService";

/* см. использование в методе haveChanges */
const editableFields = [
  "name",
  "optimizationType",
  "planTransportImportSettingsId",
  "planCargoImportSettingsId",
  "optFunction",
  "overlapTiersHeight",
  "isMixedLoading",
  "isAlternateFormatsToOverlapVerticalSeam",
];

export default defineComponent({
  components: {
    EnumControl,
    DateControl,
    DxCheckBox,
    //DxNumberBox,
    IntegerControl,
    DxButton,
    FontAwesomeIcon,
    DxTabPanel,
    TextControl,
    //CatalogueSelectControl,
    ImportAllWorkflow,
    ExportAllWorkflow,
    PlanInnerInfoDialog,
    //DxPopup,
    //DxToolbarItem,
  },
  mixins: [mountComponentMixin, pollingMixin],
  props: {},
  computed: {
    isWaiting() {
      return this.model.state == PlanState.Waiting;
    },
    isCalculation() {
      return (
        this.model.state == PlanState.Waiting ||
        this.model.state == PlanState.Running
      );
    },

    calcButtonDisabled() {
      return this.calculationStarting || this.calculationStopping;
    },

    calcButtonText() {
      return this.isCalculation ? "Остановить" : "Рассчитать";
    },

    isShowImportSettings() {
      return this.$can("view", "Administration");
    },
    tabs() {
      return [
        {
          id: 0,
          name: "Грузы",
          component: "views/journals/PlanCargo.vue",
          domId: "PlanCargoViewId",
          mounted: false,
        },
        {
          id: 1,
          name: "Транспорт",
          component: "views/journals/PlanTransports.vue",
          domId: "PlanTransportsViewId",
          mounted: false,
        },
        {
          id: 2,
          name: "Результат",
          component: "views/journals/PlanResultsView.vue",
          domId: "PlanResultsViewId",
          mounted: false,
        },
      ];
    },
    calculationTime() {
      if (this.seconds == 0) return "0 сек";

      var hr = Math.floor(this.seconds / 3600);
      var min = Math.floor(this.seconds / 60) - hr * 60;
      var sec = this.seconds % 60;

      return (
        (hr != 0 ? hr + " час " : "") +
        (min != 0 ? min + " мин " : "") +
        (sec != 0 ? sec + " сек" : "")
      );
    },
  },
  watch: {
    selectedTabIndex: function (value: any) {
      if (!this.isCreated) return;
      this.onSelectTabIndex(value);
      (this as any).rememberSettings();
    },
    isCalculation: function (value: any) {
      if (value && !this.isTimerRunning) this.startTimer();
      if (!value && this.isTimerRunning) this.stopTimer();
    },
  },
  methods: {
    onOk() {
      this.checkboxDisabled = true;
      this.popupVisible = false;
    },
    onCancel() {
      this.popupVisible = false;
    },
    onShowInfo() {
      this.$refs.infoDialog.open();
    },
    onImport() {
      (this.$refs.importWorkflow as any).start(this.model.id);
    },
    onExport() {
      (this.$refs.exportWorkflow as any).start(this.model.id);
    },
    calcuseAllRolls() {
      this.model.useAllRolls = this.model.maxRollCount == null;

      if (!this.loading)
        this.hasSettingsChanges = editableFields.some(
          (x: any) => this.savedModel[x] != this.model[x]
        );
    },
    calсCountRolls() {
      if (this.model.useAllRolls == true) this.model.maxRollCount = null;

      if (!this.loading)
        this.hasSettingsChanges = editableFields.some(
          (x: any) => this.savedModel[x] != this.model[x]
        );
    },
    onImportExportClose() {
      this.onSelectTabIndex(this.selectedTabIndex);
    },
    rememberSettings() {
      const temp = {
        settings: {
          selectedTabIndex: this.selectedTabIndex,
          showSettings: this.isSettingsOpen.shown,
          marginResultUnitType: this.marginResultUnitType,
        },
        index: this.$route.params.id,
      };
      (this as any).$store.dispatch("planSettings/setPlanSettings", temp);
    },
    async getRememberedSettings() {
      const planSettings = (this as any).$store.state.planSettings.planSettings[
        this.$route.params.id
      ];
      if (planSettings) {
        this.selectedTabIndex = planSettings.selectedTabIndex;
        this.isSettingsOpen = { shown: planSettings.showSettings };
        this.showClass = this.isSettingsOpen.shown ? "show" : "";
      }
    },
    onSelectTabIndex(value: any, isCalcualtionStarted: boolean) {
      this.$nextTick(() => {
        const tab = { ...this.tabs[value] };
        if (!tab.mounted) {
          (this as any).mountComponent(tab.component, tab.domId, {
            parentModel: { ...this.model },
            isCalculation: isCalcualtionStarted || this.isCalculation,
            isSettingsOpen: this.isSettingsOpen,
          });
          //this.tabs[value].mounted = true;
        }
      });
    },
    onSave() {
      this.saving = true;
      this.storeManager.setEditModel(this.renderKey, { ...this.model });
      this.dataSource.update(this.model.id, { ...this.model }).then(() => {
        this.saving = false;
        this.savedModel = { ...this.model };
        this.haveChanges();
      });
    },
    onCalcButtonClick() {
      if (!this.isCalculation) this.startCalculation();
      else this.stopCalculation();
    },
    onSwitchChange() {
      if (this.isSettingsOpen.shown === true)
        this.isSettingsOpen = { shown: false };
      else this.isSettingsOpen = { shown: true };
      this.rememberSettings();
    },

    async updatePlanSettingsResult() {
      const { data }: { data: any } = await api.get(`PlanSettingsResult/Get`, {
        params: {
          planId: this.model.id,
        },
      });
      this.marginResultUnitType = data.marginUnitType;
      this.rememberSettings();
    },

    startTimer() {
      this.seconds = 0;
      this.interval = setInterval(this.incrementTime, 1000);
      this.isTimerRunning = true;
    },

    stopTimer() {
      clearInterval(this.interval);
      this.isTimerRunning = false;
      const temp = {
        data: this.calculationTime,
        index: this.$route.params.id,
      };
      if (!this.calculationWasStopped)
        (this as any).$store.dispatch(
          "calculationTime/setCalculationTime",
          temp
        );
      this.calculationWasStopped = false;
    },

    incrementTime() {
      this.seconds = Math.round(new Date().getTime() / 1000 - (new Date(this.model.calcWaitingDate).getTime() - new Date(this.model.calcWaitingDate).getTimezoneOffset() * 60000) / 1000);
    },

    async startCalculation() {
      try {
        this.calculationStarting = true;
        this.stateDescription = "Запуск расчета";

        //запускаем расчет
        const urlPrefix = this.solverUrl ? this.solverUrl + "/" : "";
        await api.post(`${urlPrefix}Plan/StartCalculation`, this.model.id);
      } finally {
        //запускаем polling
        this.startStatePolling();

        if (this.selectedTabIndex != this.resultsTabId)
          this.onSelectTabIndex(this.selectedTabIndex, true);
        this.calculationStarting = false;
      }
    },
    startStatePolling() {
      const urlPrefix = this.solverUrl ? this.solverUrl + "/" : "";
      this.startPolling(
        `${urlPrefix}Plan/GetState?planId=${this.model.id}`,
        this.onPollCallback,
        3000
      );
    },
    onPollCallback(data: any) {
      this.model.state = data.state;
      this.model.calcWaitingDate = data.calcWaitingDate;
      this.model.calcRunningDate = data.calcRunningDate;
      this.model.calcCompletedDate = data.calcCompletedDate;
      this.savedModel.state = data.state;
      this.savedModel.calcWaitingDate = data.calcWaitingDate;
      this.savedModel.calcRunningDate = data.calcRunningDate;
      this.savedModel.calcCompletedDate = data.calcCompletedDate;

      this.stateDescription = data.stateDescription;

      if (
        data.state == PlanState.Canceled ||
        data.state == PlanState.Exception ||
        data.state == PlanState.Completed ||
        data.state == PlanState.NotFound
      ) {
        //Переход на вкладку результатов
        if (
          data.state == PlanState.Completed ||
          data.state == PlanState.NotFound
        ) {
          this.updatePlanSettingsResult();
          if (this.selectedTabIndex != this.resultsTabId) {
            this.selectedTabIndex = this.resultsTabId;
          } else {
            this.selectedTabIndex = this.resultsTabId;
            this.onSelectTabIndex(this.resultsTabId);
          }
        } else {
          this.onSelectTabIndex(this.selectedTabIndex);
        }
        return true;
      }
      return false;
    },
    async stopCalculation() {
      try {
        this.calculationStopping = true;
        this.calculationWasStopped = true;
        await api.post(
          `${this.solverUrl ? this.solverUrl + "/" : ""}Plan/StopCalculation`,
          this.model.id
        );
      } finally {
        this.calculationStopping = false;
      }
    },

    haveChanges() {
      // Вынужнены использовать список изменяемых полей editableFields вместо JSON.stringify, т.к. DateControl меняет
      // строковое представление исходной даты, приходящей с сервера
      if (!this.loading)
        this.hasSettingsChanges = editableFields.some(
          (x: any) => this.savedModel[x] != this.model[x]
        );
    },
    toggleTooltip() {
      this.tooltipVisible = !this.tooltipVisible;
    },
  },
  data() {
    return {
      tooltipVisible: false,
      calculationWasStopped: false,
      seconds: 0,
      interval: null,
      isTimerRunning: false,
      popupVisible: false,
      checkboxDisabled: false,
      marginResultUnitType: MarginUnitType.CubicMeters,
      hasSettingsChanges: false,
      isCreated: false,
      savedModel: {},
      solverUrl: null,
      resultsTabId: 2,
      calculationStarting: false,
      calculationStopping: false,
      tabsRenderKey: 0,
      selectedTabIndex: 0,
      showClass: "show",
      renderKey: null,
      loading: true,
      saving: false,
      model: { id: this.$route.params.id },
      guidHelper: new GuidHelper(),
      storeManager: new StoreManager(),
      dataSource: null,
      isSettingsOpen: {
        shown: true,
      },
      okButtonOptions: {
        text: "Снять",
        onClick: this.onOk,
      },
      cancelButtonOptions: {
        text: "Оставить",
        onClick: this.onCancel,
      },
      importConfig: new TableImportConfig({
        progressMethodName: "importPlanProgress",
        progressEventName: "importPlanProgressEvent",
        messageEventName: "importPlanMessageEvent",
      }),
      exportConfig: new TableExportConfig({
        progressMethodName: "exportPlanProgress",
        progressEventName: "exportPlanProgressEvent",
        messageEventName: "exportPlanMessageEvent",
      }),
      stateDescription: "",
    };
  },
  created() {
    this.getRememberedSettings();
    this.renderKey = this.guidHelper.getUniqueId(4);
    this.dataSource = this.storeManager.getStandartGridCrudStore(
      "Plan",
      this.renderKey
    );
  },
  mounted() {
    SettingsService.setSettings();
    this.dataSource
      .getEdit(this.$route.params.id)
      .then(async (model: any) => {
        await this.updatePlanSettingsResult();
        this.savedModel = { ...model };
        if (process.env.VUE_APP_DEVELOPMENT_MODE === "True") {
          this.solverUrl = process.env.VUE_APP_DEVELOPMENT_CALCULATION_SERVER;
        }
        this.model = model;
        this.stateDescription = model.stateDescription;
        this.loading = false;
        if (!this.isCalculation) {
          this.seconds = Math.round((new Date(this.model.calcCompletedDate).getTime() - new Date(this.model.calcWaitingDate).getTime()) / 1000);
        }
          
        if (this.isCalculation)
          this.startStatePolling();

        if (this.model.state == 7)
          this.selectedTabIndex = this.resultsTabId;
      })
      .then(() => {
        this.onSelectTabIndex(this.selectedTabIndex);
      });

    this.isCreated = true;
  },
  beforeUnmount() {
    this.tabs.forEach((tab: any) => {
      if (tab.mounted) this.unmountComponent(tab.domId);
    });
  },
});
