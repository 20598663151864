import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability";
import { auth } from "../store/authModule";

type Actions = "view";

export type Modules = number;

export const mapModulesPermission: Map<number, string> = new Map<
  number,
  string
>([
  [1, "Modules.OptFunction"],
  [2, "Modules.CargoRoll"],
  [4, "Modules.CargoPallet"],
  [8, "Modules.CargoPriority"],
  [16, "Modules.CargoQueue"],
  [32, "Modules.ManyTransport"],
  [64, "Modules.TransportBoxCar"],
  [128, "Modules.TransportContainer"],
  [256, "Modules.TransportMotor"],
  [512, "Modules.Order"],
]);

export type AppAbility = Ability<[Actions, string]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
export let ability: AppAbility;

export function defineRulesForPolicies(policies: string[]) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  //добавляем разрешения
  policies.forEach((p: string) => can("view", p));

  return rules;
}

export function defineRulesForModules(modules: Modules) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  mapModulesPermission.forEach((value, key) => {
    if ((modules & key) > 0) can("view", mapModulesPermission.get(key));
  });

  return rules;
}

export function getAppAbility() {
  ability = new AppAbility(
    defineRulesForPolicies(auth.getters.getPolicies(auth.state)).concat(
      defineRulesForModules(auth.getters.getModules(auth.state))
    )
  );
  return ability;
}

export function updateAppAbility() {
  ability.update(
    defineRulesForPolicies(auth.getters.getPolicies(auth.state)).concat(
      defineRulesForModules(auth.getters.getModules(auth.state))
    )
  );
}
