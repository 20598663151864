import api from "./api";
import TokenService from "./tokenService";
import router from "../router";

class AuthService {
  login({ username, password, rememberMe }: any) {
    return api
      .post("/login/login", {
        username,
        password,
        rememberMe
      })
      .then((response: any) => {
        let data = {};
        if (response.data.accessToken) {
          data = {
            username: response.data.user.userName,
            userfullname: response.data.user.userFullName,
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            policies: response.data.user.policies,
            userid: response.data.user.userId,
            modules: response.data.user.modules,
          };
          TokenService.setUser(data);
        }
        return data;
      });
  }

  logout() {
    //GET logout
    api
      .get("/login/logout", {
        params: {
          refreshToken: TokenService.getLocalRefreshToken(),
        },
      })
      .then(() => {
        TokenService.removeUser();
        router.push({ name: "login" });
      });
  }
}

export default new AuthService();
