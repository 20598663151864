import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_DxTextArea = _resolveComponent("DxTextArea")!

  return (_openBlock(), _createBlock(_component_DxTextArea, {
    value: _ctx.value,
    disabled: _ctx.disabled,
    readOnly: _ctx.readOnly,
    placeholder: _ctx.placeholder,
    isValid: _ctx.isValid,
    visible: _ctx.visible,
    hint: _ctx.hint,
    "max-length": _ctx.maxLength,
    height: _ctx.height,
    "min-height": _ctx.minHeight,
    "max-height": _ctx.maxLength,
    width: _ctx.width,
    autoResizeEnabled: _ctx.autoResizeEnabled,
    onValueChanged: _ctx.valueChanged,
    onKeyDown: _ctx.keyDown,
    onContentReady: _ctx.contentReady,
    onOnEnterKey: _ctx.enterKey,
    onInitialized: _ctx.initialized
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxValidator, { "validation-group": _ctx.validationGroup }, {
        default: _withCtx(() => [
          (!_ctx.hasEmptyValue)
            ? (_openBlock(), _createBlock(_component_DxRequiredRule, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["validation-group"])
    ]),
    _: 1
  }, 8, ["value", "disabled", "readOnly", "placeholder", "isValid", "visible", "hint", "max-length", "height", "min-height", "max-height", "width", "autoResizeEnabled", "onValueChanged", "onKeyDown", "onContentReady", "onOnEnterKey", "onInitialized"]))
}